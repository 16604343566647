import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "./components";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  GoalStatement,
  Vault,
  Profile,
  Pods,
  Affirmations,
  Gratitude,
  Quotes,
  Memories,
  MemoriesDetails,
  DailyActivities,
  Calender,
  ProgrammRecordingDetail,
  AffirmationList,
} from "./pages/index";
import LessonsReSource from "./pages/lessonsResource/LessonsResource";
import ReviewCards from "./pages/ReviewCard/ReviewCards";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import AddGratitude from "./pages/Gratitude/AddGratitude";
import MemoriesDetail from "./pages/Memories/MemoriesDetail";
import SourcesFiles from "./pages/SourceCard/SourcesFiles";
import BobVideos from "./pages/BOBVideos/BobVideos";
import LessonsNotes from "./components/LessonNotes/LessonsNotes";
import LessonsRecording from "./pages/LessonsRecording";
import AddProgram from "./pages/Programmesold/AddProgram";
import Addlesson from "./pages/Programmesold/AddLesson";
import AddProgramReview from "./pages/ReviewCard/AddProgramReview";
import AddDocument from "./pages/SourceCard/AddDocument";
import EditProgram from "./pages/Programmesold/EditProgram";
import AddLessonsDoc from "./pages/Programmesold/AddLessonsDoc";
import AddLessonsRecording from "./pages/Programmesold/AddLessonRecording";
import DeleteProgram from "./components/_dashboard/programmes/Deleteprogram";
import EditLesson from "./pages/Programmesold/EditLesson";
import EditProgramReview from "./pages/ReviewCard/EditReview";
import EditProgramDocument from "./pages/SourceCard/EditProgramDocument";
import Rooms from "./pages/Room/Rooms";
import AddRooms from "./pages/Room/AddRooms";
import RecordingsList from "./pages/Recordings/RecordingsList";
import AddQuestions from "./pages/GoalStatement/AddQuestions";
import EditQuestions from "./pages/GoalStatement/EditQuestion";
import AddRecording from "./pages/Recordings/AddRecording";
import EditRoom from "./pages/Room/EditRoom";
import EditRecording from "./pages/Recordings/EditRecording";
import EditProfile from "./pages/Profile/EditProfile";
import Calenders from "./pages/Calender/CalendarEvents";
import RecordingDetails from "./pages/Recordings/RecordingDetail";
import RoomDetail from "./pages/Room/DetailRoom";
import DetailQuestion from "./pages/GoalStatement/DetailQuestion";
import ListEvents from "./pages/Calender/ListEvents";
import EventDetailList from "./pages/Calender/EventDetailList";
import SupportTicket from "./pages/SupportTicket/SupportTicket_old";
import SupportTicketDetail from "./pages/SupportTicket/SupportTicketDetail";
import Community from "./pages/Community/Community";
import MemberGoalStatement from "./pages/GoalStatementMembers";
import MemberGoalstatementList from "./pages/MemberGoalStatement/MemberGoalstatementList";
import { AnswerHistory } from "./pages/MemberGoalStatement/AnswerHistory";
import { QuestionsReply } from "./pages/MemberGoalStatement/QuestionsReply";
import MemberProfile from "./pages/MemberProfile/MemberProfile";
import MemberGroupsList from "./pages/MemberProfile/MembersGroupList";
import MemberPersonalNote from "./pages/MemberProfile/MemberPersonalNote";
import Todo from "./pages/MemberProfile/Todo";
import MemberGoalStatementIncomplete from "./pages/GoalStatementMembersInComlete";
import PaymentTemplateList from "./pages/PaymentTemplate/PaymentTemplateList";
import AddPaymentTemplate from "./pages/PaymentTemplate/AddPaymentTemplate";
import EditPaymentTemplate from "./pages/PaymentTemplate/EditPaymentTemplate";
import PaymentTransactionsList from "./pages/TransactionList/TransactionsList";
import StripeProductsList from "./pages/Payments/StripeProductsList";
import AddStripeProduct from "./pages/Payments/AddStripeProduct";
import EditStripeProduct from "./pages/Payments/EditStripeProduct";
import PaymentRequestInfo from "./pages/PaymentRequest/PaymentRequestInfo";
import LoginAsConsultant from "./pages/LoginAsConsultant";
import LoginByAdmin from "./pages/LoginByAdmin";
import MemberGoalStatementSaveAndClose from "./pages/GoalStatementSaveAndClose";
import WheelOfLife from "./pages/Member/ProgrammDetailTab";
import Tutorials from "./pages/Tutorials/TutorialsList";
import TutorialDetail from "./pages/Tutorials/TutorialDetail";
import PagesList from "./pages/WebPages/WebsitePages/PagesList";
import AddOrUpdatePage from "./pages/WebPages/WebsitePages/AddOrUpdatePage";
import UpdatePageContent from "./pages/WebPages/WebsitePages/UpdatePageContent";
import WebPageTestimonials from "./pages/WebPages/WebPageTestimonials/WebPageTestimonials";
import AddOrUpdateWebPageTestimonial from "./pages/WebPages/WebPageTestimonials/AddOrUpdateWebPageTestimonial";
import WebsiteProgrammesList from "./pages/WebPages/WebsiteProgrammes/WebsiteProgrammesList";
import AddOrUpdateProgramme from "./pages/WebPages/WebsiteProgrammes/AddOrUpdateProgramme";
import BusinessStrategyList from "./pages/WebPages/BusinessStrategy/BusinessStrategyList";
import AddOrUpdateBusinessStrategy from "./pages/WebPages/BusinessStrategy/AddOrUpdateBusinessStrategy";
import WebPageFaqs from "./pages/WebPages/WebPageFaqs/WebPageFaqs";
import AddOrUpdateWebPageFaq from "./pages/WebPages/WebPageFaqs/AddOrUpdateWebPageFaq";
import { QuestionSurvey } from "./pages/WebPages/QuestionSurvey/QuestionSurvey";
import WebPagePaymentPlans from "./pages/WebPages/WebPagePaymentPlans/WebPagePaymentPlans";
import AddOrUpdatePaymentPlan from "./pages/WebPages/WebPagePaymentPlans/AddOrUpdatePaymentPlan";
import SuccessScheduleList from "./pages/WebPages/SuccessSchedule/SuccessScheduleList";
import AddOrUpdateSuccessSchedule from "./pages/WebPages/SuccessSchedule/AddOrUpdateSuccessSchedule";
import LessonListing from "./pages/Programmes/LessonListing";
import LessonsDetail from "./pages/Programmes/LessonsDetail";
import AffiliateInformation from "./pages/AffiliateInformation/AffiliateInformation";
import SupportTicket11 from "./pages/SupportTicket/SupportTicket";
import SupportTicketNew from "./pages/SupportTicket/SupportTicket";
import ProgrammRecordingDetails from "./pages/Programmes/ProgrammRecordingDetail";
import LinkList from "./pages/WebPages/WebsitePages/LinkList";
import DelegateQuestionsList from "./pages/DelegateQuestions/DelegateQuestion";
import DelegateQuestionsListView from "./pages/DelegateQuestions/DelegateQuestionsList";
import PostDetails from "./pages/Community/PostDetails";
import { VideoSeries } from "./pages/videoSeries/VideoSeries";
import NinetyQuestionsListView from "./pages/90DayQuestions/NinetyDayQuestionsList";
import NewPods from "./pages/NewPods/NewPods";
import NewPodsRoomDetail from "./pages/NewPods/NewPodsRoomDetail";
import DelegateEvent from "./pages/DelegateEventCalender/DelegateEventCalender";
import ContactSupport from "./pages/SupportTicket/ContactSupport";
import ContactSupportTicketDetail from "./pages/SupportTicket/ContactSupportTicketDetail";
import NintyDayTracker from "./pages/90DayPlan/NintyDayTracker";
import MemberSubscribersList from "./pages/Member/MemberSubscribersList";
import { DigitalAssets } from "./pages/DigitalAssets/DigitalAssets";
import DigitalAssetsListings from "./pages/DigitalAssets/DigitalAssetsListings";
import Appointment from "./pages/Appointment/Appointment";
import AddAppointment from "./pages/Appointment/AddAppointment";
import AttitudeAssessmentList from "./pages/AttitudeAssessment/AttitudeAssessmentList";
import AssessmentHistory from "./pages/AttitudeAssessment/AssessmentHistory";
import MemberAttitudeAssessmentList from "./pages/MemberProfile/MemberAttitudeAssessmentList";
import AutoRespondedMessage from "./pages/AutoRespondedMessage/AutoRespondedMessage";
import AddAutoRespondedMessage from "./pages/AutoRespondedMessage/AddAutoRespondedMessage";
import UpdateAutoRespondedMessage from "./pages/AutoRespondedMessage/UpdateAutoRespondedMessage";
import MemberDailyDynamiteList from "./pages/MemberProfile/MemberDailyDynamiteList";
import Progress from "./pages/Progress/Progress";
import QuestionsAndAssessment from "./pages/QuestionsScreen/QuestionsAndAssessment";
import AssessmentQuestionsSection from "./pages/QuestionsScreen/Assessment";
import ThankYou from "./pages/QuestionsScreen/ThankYou";
import WheelOfLifeMemberDetailQuestion from "./pages/WheelOfLifeMemberList.js/DetailQuestion";
import AllSales from "./pages/salesLeads/allSales";
import AllSalesPage from "./pages/salesLeads/AllSalesPage";
import BookingDetail from "./pages/Booking/BookingDetail";
import QuestionsAnswersForUser from "./pages/GeneralQuestions/QuestionsAnswersForUser";
import QuestionsAnswers from "./pages/GeneralQuestions/QuestionsAnswers";
import { GeneralQuestions } from "./pages/GeneralQuestions/GeneralQuestions";
import QuestionsAnswersAsCreatedFor from "./pages/GeneralQuestions/QuestionsAnswersAsCreatedFor";
import BookingList from "./pages/Booking/BookingList";
import ZoomSetting from "./pages/Profile/ZoomSetting";
import Chat from "./pages/Chat/Chat";
import LiveEvents from "./pages/LiveEvents/LiveEvents";
import LiveEventsDetail from "./pages/LiveEvents/LiveEventsDetail";
import GeneralQuestionsList from "./pages/GeneralQuestions/GeneralQuestionsList";
import QuestionsAnswersList from "./pages/Questions/QuestionsAnswersList";
import NewCalendar from "./pages/Calender/NewCalendar";
import CalendarEventMembers from "./pages/Calender/CalendarEventMembers";
import CalendarEventsList from "./pages/Calender/CalendarEventsList";
import VenuesList from "./pages/WebPages/Venues/VenuesList";
import AddOrUpdateVenues from "./pages/WebPages/Venues/AddOrUpdateVenues";
import BannerSliderList from "./pages/WebPages/BannerSlider/BannerSliderList";
import AddBannerSlider from "./pages/WebPages/BannerSlider/AddBannerSlider";
import SubscriptionList from "./pages/SubscriptionList/SubscriptionList";
import PersonalMemberNotes from "./pages/MemberProfile/PersonalMemberNotes";
import AddOrUpdateDynamiteEvent from "./pages/LiveEvents/AddOrUpdateDynamiteEvent";
import UpdateLockEventContent from "./pages/LiveEvents/LockEventContent";
import EventConfiguration from "./pages/LiveEvents/EventConfiguration";
import EventVideoCategories from "./pages/LiveEvents/Categories/EventVideoCategories";
import AddOrUpdateEventCategory from "./pages/LiveEvents/Categories/AddOrUpdateEventCategory";
import DynamiteEventVideoList from "./pages/LiveEvents/Categories/Videos/DynamiteEventVideoList";
import AddOrUpdateDynamiteEventVideo from "./pages/LiveEvents/Categories/Videos/AddOrUpdateDynamiteEventVideo";
import DynamiteEventMembers from "./pages/LiveEvents/EventMembers/DynamiteEventMembers";
import AddDynamiteEventMember from "./pages/LiveEvents/EventMembers/AddDynamiteEventMember";
import EditDynamiteEventMember from "./pages/LiveEvents/EventMembers/EditDynamiteEventMember";
import GeneralEvents from "./pages/LiveEvents/GeneralEvents/GeneralEvents";
import AddOrUpdateGeneralEvents from "./pages/LiveEvents/GeneralEvents/AddOrUpdateGeneralEvents";
import QuestionConfigurationForEvent from "./pages/LiveEvents/Categories/Videos/QuestionConfigurationForEvent";
import { GeneralQuestionsEvents } from "./DAL/LiveEvents/Categories/EventsCategoryVideos/GeneralQuestions";
import UpdateThanksContent from "./pages/WebPages/WebsitePages/UpdateThanksContent";
import SocialSharingSetting from "./pages/WebPages/WebsitePages/SoicalSharingSetting";
import UnpublishSetting from "./pages/WebPages/WebsitePages/UnpublishSetting";
import DinnerMenuList from "./pages/DinnerMenu/DinnerMenuList";
import AddOrUpdateDinnerMenu from "./pages/DinnerMenu/AddOrUpdateDinnerMenu";
import SelfImageCompleted from "./pages/SelfImage/SelfImageCompleted";
import SelfImageInCompleted from "./pages/SelfImage/SelfImageInCompleted";
import SelfImageResponded from "./pages/SelfImage/SelfImageResponded";
import SelfImageDetail from "./pages/SelfImage/SelfImageDetail";
import AllMembersList from "./pages/Member/AllMembersList";
import BookingNotes from "./pages/Booking/BookingNotes";
import Shop from "./pages/DDShop/Shop";
import ShopItemDetail from "./pages/DDShop/ShopItemDetail";
import ShoppingCart from "./pages/DDShop/ShoppingCart";
import ProgrammeAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/ProgrammeAccessPaymentPlan";
import EventAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/EventAccessPaymentPlan";
import AgreementConfiguration from "./pages/WebPages/WebPagePaymentPlans/AgreementConfiguration";
import MembersList from "./pages/Member/MembersList";
import NurtureMembersList from "./pages/Member/NurtureMembersList";
import InternalTickets from "./pages/InternalTicket/InternalTickets";
import InternalTicketDetail from "./pages/InternalTicket/InternalTicketDetail";
import PaymentRequestCommissionConfiguration from "./pages/PaymentRequest/PaymentRequestCommissionConfiguration";
import PaymentRequestAgreementConfiguration from "./pages/PaymentRequest/PaymentRequestAgreementConfiguration";
import WelcomeReminderSetting from "./components/WelcomeReminderSetting";
import LeadStatusHistory from "./pages/Member/LeadStatusHistory";
import DigitalAssetsCategories from "./pages/DigitalAssets/DigitalAssetsCategories";
import BenefitList from "./pages/WebPages/ProgressReport/BenefitList";
import AddOrUpdateBenefit from "./pages/WebPages/ProgressReport/AddOrUpdateBenefit";
import MinimalLayout from "./layouts/MinimalLayout";
import LiveStream from "./pages/Live-Stream/LiveStream";
import SettingsLayout from "./layouts/SettingsLayout";
import PaymentRequestInfoFile from "./pages/PaymentRequest/PaymentRequestInfoFile";
import MyLiveEvents from "./pages/LiveEvents/MyLiveEvents";
import CalendarPodsList from "./pages/NewPods/CalendarPodsList";
import CalendarPodsListBookcall from "./pages/NewPods/CalendarPodsListBookcall";
import CalendarPodsDetail from "./pages/Room/CalendarPodsDetail";
import CalendarPodsDetailBook from "./pages/Room/CalendarPodsDetailBook";
import WhatsAppChat from "./pages/Chat/WhatsAppChat";
import AccountabilityTracker from "./components/AccountabilityTracker/AccountabilityTracker";
import AccountabilityReport from "./components/AccountabilityTracker/AccountabilityReport";
import PerformanceStreak from "./components/AccountabilityTracker/PerformanceStreak";
import PerformanceStreakcopy from "./components/AccountabilityTracker/PerformanceStreakcopy";
import DiaryList from "./components/AccountabilityTracker/diarylist";
import PerformanceStreakPastActivities from "./components/AccountabilityTracker/PerformanceStreakPastActivities";
import AddOrUpdateEvent from "./pages/Calender/AddOrUpdateEvent";
import AddOrUpdateDelegateEvent from "./pages/Calender/AddOrUpdateDelegateEvent";
import BroadCastChat from "./pages/Chat/BroadCastChat/BroadCastChat";
import MonthlyAccountabilityReport from "./components/AccountabilityTracker/MonthlyAccountabilityReport";
import PaymentRequestsList from "./pages/PaymentRequest/PaymentRequestsList";
import QuarterQuestions from "./pages/QuarterQuestions/QuarterQuestions";
import AccountabilityTrackerForSpacificUser from "./components/AccountabilityTracker/AccountabilityTrackerForSpacificUser";
import AddOrUpdatePaymentRequest from "./pages/PaymentRequest/AddOrUpdatePaymentRequest";
import CalendarGroupsList from "./pages/Groups/CalendarGroupsList";
import AddOrUpdateCalendarGroup from "./pages/Groups/AddOrUpdateCalendarGroup";
import CalendarGroupDetail from "./pages/Groups/CalendarGroupDetail";
import SalesTeam from "./pages/SalesTeam/SalesTeam";
import AddOrUpdateSalesTeam from "./pages/SalesTeam/AddOrUpdateSalesTeam";
import ManageNavItemsAccess from "./pages/SalesTeam/ManageNavItemsAccess";
import LiveEventVideoDetails from "./pages/Community/LiveEventVideoDetails";
import PendingCommissions from "./pages/SalesTeam/PendingCommissions";
import AffiliateTransactions from "./pages/SalesTeam/AffiliateTransactions";
import AddOrUpdateAffiliateTransactions from "./pages/SalesTeam/AddOrUpdateAffiliateTransactions";
import SalesTeamCommissionsDetail from "./pages/SalesTeam/SalesTeamCommissionsDetail";
import LeadsCenter from "./pages/SalesTeam/LeadsCenter";
import CommissionPLans from "./pages/WebPages/WebsitePages/CommissionPlans";
import ManageSalesTeamCommission from "./pages/WebPages/WebsitePages/ManageSalesTeamCommission";
import EventDetailListNew from "./pages/Calender/EventDetailListNew";
import MembersForSubTeam from "./pages/Member/MembersForSubTeam";
import { useContentSetting } from "./Hooks/ContentSettingState";
import AddOrUpdateMyDynamiteEvent from "./pages/LiveEvents/AddOrUpdateMyDynamiteEvent";
import VideoRecorderUploader from "./pages/LiveStream";
import ProgrammeAccessPaymentRequest from "./pages/PaymentRequest/ProgrammeAccessPaymentRequest";
import ManageSubteamAccess from "./pages/WebPages/WebsitePages/ManageSubteamAccess";
import LinksTabs from "./pages/WebPages/WebsitePages/LinksTAbs";

// ----------------------------------------------------------------------

export default function Router() {
  const { userInfo, isAccess } = useContentSetting();
  const Authentication = () => {
    if (localStorage.getItem("token")) {
      if (isAccess?.other_link) {
        window.location.href = isAccess?.other_link;
      } else {
        return <Navigate to="/dashboard"> </Navigate>;
      }
    }
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login"> </Navigate>;
    }
  };
  return (
    <Routes>
      <Route element={<MinimalLayout />}>
        <Route path="/live-stream/:topic/:token" element={<LiveStream />} />
      </Route>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route element={<SettingsLayout />}>
          <Route
            path="/welcome-reminder-setting"
            element={<WelcomeReminderSetting />}
          />
          <Route path="/zoom-setting" element={<ZoomSetting />} />
        </Route>
        <Route path="/dashboard" element={<DashboardApp />} />
        <Route
          path="/thesource"
          element={<Community list_type="the_cosmos" />}
        />
        <Route
          path="/scheduled-feeds"
          element={<Community list_type="scheduled" is_scheduled={true} />}
        />
        <Route
          path="/sourcefeeds"
          element={<Community list_type="the_source" />}
        />
        <Route
          path="/all-sourcefeeds"
          element={<Community list_type="all_source" />}
        />
        <Route
          path="/thesource/feed-detail/:post_id"
          element={<PostDetails feeds_type="general" list_type="the_cosmos" />}
        />
        <Route
          path="/all-sourcefeeds/feed-detail/:post_id"
          element={<PostDetails feeds_type="general" list_type="all_source" />}
        />
        <Route
          path="/sourcefeeds/feed-detail/:post_id"
          element={<PostDetails feeds_type="general" list_type="the_source" />}
        />
        <Route
          path="/scheduled-feeds/feed-detail/:post_id"
          element={
            <PostDetails
              feeds_type="general"
              list_type="scheduled"
              is_scheduled={true}
            />
          }
        />
        <Route
          path="/feed-detail/:post_id"
          element={<PostDetails feeds_type="general" list_type="the_source" />}
        />
        <Route path="/tutorials" element={<Tutorials />} />
        {/* <Route path="/TestTable" element={<TestTable />} /> */}
        <Route
          path="/tutorials/tutorial-detail/:id"
          element={<TutorialDetail />}
        />
        <Route path="/delegate-training" element={<Programmes />} />
        <Route path="/delegate-question" element={<DelegateQuestionsList />} />
        <Route
          path="/delegate-question/questions-list"
          element={<GeneralQuestionsList created_for="programme" />}
        />
        <Route
          path="/90-day-plan"
          element={
            <GeneralQuestionsList
              created_for="delegate-90-day-questions"
              remove_back_button={true}
            />
          }
        />
        <Route path="/Editprofile" element={<EditProfile />} />
        <Route path="/delegate-training/:slug" element={<ProgrammesDetail />} />
        <Route
          path="/delegate-training/:program_slug/lessons"
          element={<LessonListing />}
        />
        <Route path="/lessons/:lesson_slug" element={<LessonsDetail />} />
        <Route
          path="/members"
          element={
            userInfo.team_type == "sub_team" ? (
              <MembersForSubTeam />
            ) : (
              <MembersList />
            )
          }
        />
        <Route path="/all-members" element={<AllMembersList />} />
        <Route path="/nurture-members" element={<NurtureMembersList />} />
        <Route
          path="/nurture-members/personel-notes/:id"
          element={<PersonalMemberNotes type="nurture_members" />}
        />
        <Route
          path="/nurture-members/questions-answers"
          element={<QuestionsAnswersForUser />}
        />
        <Route
          path="/nurture-members/questions-answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/members/questions-answers"
          element={<QuestionsAnswersForUser />}
        />{" "}
        <Route
          path="/all-members/questions-answers"
          element={<QuestionsAnswersForUser />}
        />
        <Route
          path="/members/questions-answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/members/lessons-question/answers"
          element={<QuestionsAnswers />}
        />{" "}
        <Route
          path="/nurture-members/lessons-question/answers"
          element={<QuestionsAnswers />}
        />{" "}
        <Route
          path="/all-members/lessons-question/answers"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/leads-center/lessons-question/answers"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/members/subscribers/:member_id"
          element={<MemberSubscribersList />}
        />
        <Route
          path="/all-members/subscribers/:member_id"
          element={<MemberSubscribersList />}
        />
        <Route
          path="/nurture-members/subscribers/:member_id"
          element={<MemberSubscribersList />}
        />
        {/* <Route path="/members/questions/:id" element={<QuestionsTabs />} /> */}
        <Route
          path="/website-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        />
        <Route
          path="/website-pages/:page_id/food-menu"
          element={<DinnerMenuList />}
        />
        <Route
          path="/website-pages/:page_id/food-menu/add-food-menu"
          element={<AddOrUpdateDinnerMenu />}
        />
        <Route
          path="/website-pages/:page_id/food-menu/edit-food-menu/:strategy_id"
          element={<AddOrUpdateDinnerMenu />}
        />
        <Route path="/members/questions/:id" element={<WheelOfLife />} />
        <Route
          path="/nurture-members/questions/:id"
          element={<WheelOfLife />}
        />
        <Route
          path="/members/goalstatement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route path="/tracker/past-activities" element={<DiaryList />} />
        <Route path="/members/profile/:id" element={<MemberProfile />} />
        <Route path="/all-members/profile/:id" element={<MemberProfile />} />
        <Route path="/leads-center/profile/:id" element={<MemberProfile />} />
        <Route
          path="/member-answers-list"
          element={
            <QuestionsAnswersList user_type="" heading="Member Answers List" />
          }
        />
        <Route
          path="/member-answers-list/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/dashboard/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/nurture-members/profile/:id"
          element={<MemberProfile />}
        />
        <Route
          path="/members/member-question/:created_for_id"
          element={
            <QuestionsAnswersAsCreatedFor created_for="dynamite_event_video" />
          }
        />
        <Route
          path="/members/wheel-of-life/:id"
          element={<WheelOfLifeMemberDetailQuestion />}
        />
        <Route
          path="/members/assessment/:id"
          element={<MemberAttitudeAssessmentList />}
        />
        <Route
          path="/members/daily-dynamite/:id"
          element={<MemberDailyDynamiteList />}
        />
        <Route path="/members/history/:id" element={<AssessmentHistory />} />
        <Route path="/members/groups/:id" element={<MemberGroupsList />} />
        <Route path="/members/to-do/:id" element={<Todo />} />
        <Route
          path="/members/personal_note/:id"
          element={<MemberPersonalNote />}
        />
        <Route
          path="/member-completed-goalstatements"
          element={<MemberGoalStatement />}
        />
        <Route
          path="/member-incomplete-goalstatements"
          element={<MemberGoalStatementIncomplete />}
        />
        <Route
          path="/member-responded-goalstatements"
          element={<MemberGoalStatementSaveAndClose />}
        />
        <Route
          path="/self-image-completed"
          element={<SelfImageCompleted type="completed" />}
        />
        <Route
          path="/self-image-incompleted"
          element={<SelfImageInCompleted type="incompleted" />}
        />
        <Route
          path="/self-image-responded"
          element={<SelfImageResponded type="responded" />}
        />
        <Route path="/self-image-completed/:id" element={<SelfImageDetail />} />
        <Route
          path="/self-image-incompleted/:id"
          element={<SelfImageDetail />}
        />{" "}
        <Route
          path="/self-image-responded/:id"
          element={<SelfImageDetail type="responded" />}
        />
        {/* <Route
          path="/digital-asset"
          element={<DigitalAssets />}
        /> */}
        <Route path="/digital-asset" element={<DigitalAssetsCategories />} />
        <Route
          path="/digital-asset/:category_id"
          element={<DigitalAssetsListings />}
        />
        <Route
          path="/members/goal-statement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-completed-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-incomplete-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-responded-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-goalstatement/answer-history/:id"
          element={<AnswerHistory />}
        />
        <Route path="/payment-template" element={<PaymentTemplateList />} />
        <Route
          path="/payment-template/add-template"
          element={<AddPaymentTemplate />}
        />
        <Route
          path="/auto-responded-message"
          element={<AutoRespondedMessage />}
        />
        <Route
          path="/auto-responded-message/add-message"
          element={<AddAutoRespondedMessage />}
        />
        <Route
          path="/auto-responded-message/edit-message/:message_id"
          element={<UpdateAutoRespondedMessage />}
        />
        <Route
          path="/auto-responded-message"
          element={<AutoRespondedMessage />}
        />
        <Route
          path="/auto-responded-message/add-message"
          element={<AddAutoRespondedMessage />}
        />
        <Route
          path="/auto-responded-message/edit-message/:message_id"
          element={<UpdateAutoRespondedMessage />}
        />
        <Route
          path="/payment-template/edit-template/:id"
          element={<EditPaymentTemplate />}
        />
        <Route path="/payment-request" element={<PaymentRequestsList />} />
        <Route
          path="/payment-request/payment-program-access/:plan_id"
          element={<ProgrammeAccessPaymentRequest />}
        />
        <Route
          path="/payment-request/payment-request-detail/:request_title"
          element={<PaymentRequestInfoFile />}
        />
        <Route
          path="/payment-request/payment-agreement-config/:plan_id"
          element={<PaymentRequestAgreementConfiguration />}
        />
        <Route
          path="/payment-request/add-payment-request"
          element={<AddOrUpdatePaymentRequest />}
        />
        <Route
          path="/payment-request/edit-payment-request/:request_slug"
          element={<AddOrUpdatePaymentRequest />}
        />
        <Route
          path="/payment-request/commission-configuration/:plan_id"
          element={<PaymentRequestCommissionConfiguration />}
        />
        <Route path="/stripe-product" element={<StripeProductsList />} />
        <Route
          path="/stripe-product/add-product"
          element={<AddStripeProduct />}
        />
        <Route
          path="/lessons-recordings/:recording_slug"
          element={<ProgrammRecordingDetails />}
        />
        <Route
          path="/stripe-product/edit-product/:id"
          element={<EditStripeProduct />}
        />
        <Route path="/Transaction-list" element={<PaymentTransactionsList />} />
        <Route path="/booking" element={<BookingList />} />
        <Route path="/booking/answers-detail" element={<QuestionsAnswers />} />
        <Route path="/booking/:booking_id" element={<BookingDetail />} />
        <Route path="/assessment" element={<AttitudeAssessmentList />} />
        <Route path="/assessment/history/:id" element={<AssessmentHistory />} />
        <Route
          path="/member-goalstatement/question-reply/:id"
          element={<QuestionsReply />}
        />
        <Route path="/pods" element={<Rooms />} />
        <Route path="/source-pods" element={<CalendarPodsList />} />
        <Route path="/source-pods/add-pod" element={<AddRooms />} />
        <Route path="/source-pods/edit-pod/:id" element={<EditRoom />} />
        <Route
          path="/source-pods/pods-detail/:pod_slug"
          element={<CalendarPodsDetail />}
        />
        <Route path="/book-call-pods" element={<CalendarPodsListBookcall />} />
        <Route path="/book-call-pods/add-pod" element={<AddRooms />} />
        <Route path="/book-call-pods/edit-pod/:id" element={<EditRoom />} />
        <Route
          path="/book-call-pods/pods-detail/:pod_slug"
          element={<CalendarPodsDetailBook />}
        />
        <Route path="/dynamite-pods" element={<NewPods />} />
        <Route path="/pods/add-pod" element={<AddRooms />} />
        <Route path="/pods/edit-pod/:id" element={<EditRoom />} />
        <Route path="/recordings" element={<RecordingsList />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:item_slug" element={<ShopItemDetail />} />
        <Route path="/shop/shopping-cart" element={<ShoppingCart />} />
        <Route path="/recordings/add-recording" element={<AddRecording />} />
        <Route path="/recordings/Edit-recording" element={<EditRecording />} />
        <Route path="/goal-statement/add-question" element={<AddQuestions />} />
        <Route path="/support-ticket" element={<SupportTicketNew />} />
        <Route path="/internal-tickets" element={<InternalTickets />} />
        <Route
          path="/internal-tickets/answered"
          element={<InternalTickets />}
        />
        <Route
          path="/internal-tickets/needs-attention"
          element={<InternalTickets />}
        />
        <Route path="/internal-tickets/solved" element={<InternalTickets />} />
        <Route
          path="/internal-tickets/:id"
          element={<InternalTicketDetail />}
        />{" "}
        <Route
          path="/internal-tickets/answered/:id"
          element={<InternalTicketDetail />}
        />{" "}
        <Route
          path="/internal-tickets/needs-attention/:id"
          element={<InternalTicketDetail />}
        />{" "}
        <Route
          path="/internal-tickets/solved/:id"
          element={<InternalTicketDetail />}
        />
        <Route
          path="/support-ticket/member-tickets/:member_id"
          element={<SupportTicketNew />}
        />
        <Route path="/contact-support" element={<ContactSupport />} />
        <Route
          path="/contact-support/:id"
          element={<ContactSupportTicketDetail />}
        />
        {/* wheel of life  */}
        {/* <Route path="/questions" element={<QuestionsAndAssessment />} />
        <Route
          path="/questions/assessment"
          element={<AssessmentQuestionsSection />}
        />
        <Route path="/questions/assessment/thank-you" element={<ThankYou />} /> */}
        {/* wheel of life  end*/}
        <Route path="/chat" element={<Chat />} />
        <Route path="/broadcast-chat" element={<BroadCastChat />} />
        <Route path="/members/testing" element={<VideoRecorderUploader />} />
        <Route path="/tracker" element={<AccountabilityTracker />} />
        <Route
          path="/accountability-tracker-report/delegate/:id"
          element={<AccountabilityTrackerForSpacificUser />}
        />
        <Route path="/tracker/:id" element={<AccountabilityTracker />} />
        <Route path="/performance-streak" element={<PerformanceStreakcopy />} />
        <Route
          path="/performance-streak/past-activities"
          element={<PerformanceStreakPastActivities />}
        />
        <Route
          path="/accountability-tracker-report"
          element={<AccountabilityReport />}
        />
        <Route path="quarter-questions" element={<QuarterQuestions />} />
        <Route
          path="/performance-stats"
          element={<MonthlyAccountabilityReport />}
        />
        <Route path="/whatsapp-chat" element={<WhatsAppChat />} />
        <Route path="/sales-team" element={<SalesTeam />} />
        <Route
          path="/sales-team/commission-detail/:id"
          element={<SalesTeamCommissionsDetail />}
        />
        <Route
          path="/website-links/set-commission/:page_id/sales-team-commission/:id"
          element={<ManageSalesTeamCommission />}
        />
        <Route
          path="/website-pages/set-commission/:page_id/sales-team-commission/:id"
          element={<ManageSalesTeamCommission />}
        />
        <Route
          path="/sales-team/add-sales-team"
          element={<AddOrUpdateSalesTeam />}
        />
        <Route
          path="/sales-team/add-sales-team/:id"
          element={<AddOrUpdateSalesTeam type="edit" />}
        />
        <Route
          path="/sales-team/manage-navitem-access/:id"
          element={
            <ManageNavItemsAccess
              type="delegate"
              title="Delegate"
              navigate="/sales-team"
            />
          }
        />
        <Route path="/whatsapp-chat/:user_id" element={<WhatsAppChat />} />
        <Route path="/90-day-tracker" element={<NintyDayTracker />} />
        <Route path="/support-ticket/:id" element={<SupportTicketDetail />} />
        <Route
          path="/goal-statement/edit-question/:slug"
          element={<EditQuestions />}
        />
        <Route
          path="/goal-statement/detail-question/:slug"
          element={<DetailQuestion />}
        />
        <Route
          path="programmes/lessons_detail/resources/:slug"
          element={<LessonsReSource />}
        />
        <Route
          path="/programmes/deleteProgram/:slug"
          element={<DeleteProgram />}
        />
        <Route path="/lessons/:id" element={<LessonListing />} />
        <Route path="/review/:slug" element={<ReviewCards />} />
        <Route
          path="/programmes/addReview/:slug"
          element={<AddProgramReview />}
        />
        <Route
          path="/programmes/editProgrammeReview/:programSlug/:reviewSlug"
          element={<EditProgramReview />}
        />
        <Route path="/programmes/editprogram/:id" element={<EditProgram />} />
        <Route path="/programmes/editprogram/:slug" element={<EditProgram />} />
        <Route
          path="/programmes/editresource/:programSlug/:sourceSlug"
          element={<EditProgramDocument />}
        />
        <Route path="/programmes/sources/:slug" element={<SourcesFiles />} />
        <Route path="/programmes/Addsources/:id" element={<AddDocument />} />
        <Route
          path="/groups/add-group"
          element={<AddOrUpdateCalendarGroup />}
        />
        <Route
          path="/groups/edit-group/:group_slug"
          element={<AddOrUpdateCalendarGroup />}
        />
        <Route
          path="/groups/group-detail/:group_slug"
          element={<CalendarGroupDetail />}
        />
        <Route path="/groups" element={<CalendarGroupsList />} />
        <Route
          path="/programmes/lesson_sources/:programslug/:lessonSlug"
          element={<LessonsReSource />}
        />
        <Route
          path="/lessons/addlessonsDocument/:id"
          element={<AddLessonsDoc />}
        />
        <Route path="/lessons/addlesson/:slug" element={<Addlesson />} />
        <Route
          path="/lessons/Editlesson/:lessonSlug"
          element={<EditLesson />}
        />
        <Route
          path="/lessons/addlessonsRecording/:programSlug/:lessonSlug"
          element={<AddLessonsRecording />}
        />
        <Route path="/programmes/lesson/notes/:id" element={<LessonsNotes />} />
        <Route path="/programmes/BobVideos/:id" element={<BobVideos />} />
        <Route
          path="/programmes/lessons_detail/:slug1/:slug"
          element={<LessonsDetail />}
        />
        <Route
          path="/lesson_recording_detail/:lessonSlug"
          element={<LessonsRecording />}
        />
        <Route
          path="/delegate-events"
          element={
            <NewCalendar
              created_for="consultant_user"
              showGoogleCalender={true}
            />
          }
        />
        <Route
          path="/programmes/programm_recording_detail/48ce0817-9219-4977-9149-168e020ad288"
          element={<ProgrammRecordingDetail />}
        />
        <Route path="/programmes/addProgram" element={<AddProgram />} />
        <Route path="/goal-statement" element={<GoalStatement />} />
        <Route path="/vault" element={<Vault />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        {/* <Route path="/pods" element={<Pods />} /> */}
        <Route path="/affirmations" element={<Affirmations />} />
        <Route
          path="/affirmations/affirmationList"
          element={<AffirmationList />}
        />
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/gratitude/addgratitude" element={<AddGratitude />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/memories" element={<Memories />} />
        <Route
          path="/memories/memories_detail/:id"
          element={<MemoriesDetail />}
        />
        <Route
          path="/live-events/add-events"
          element={<AddOrUpdateDynamiteEvent />}
        />
        <Route
          path="/my-events/add-events"
          element={<AddOrUpdateMyDynamiteEvent />}
        />
        <Route
          path="/my-events/lock-event-content/:event_id"
          element={<UpdateLockEventContent />}
        />
        <Route
          path="/live-events/configuration/:event_id"
          element={<EventConfiguration />}
        />{" "}
        <Route
          path="/my-events/configuration/:event_id"
          element={<EventConfiguration />}
        />
        <Route
          path="/dynamite-event-categories/:event_id"
          element={<EventVideoCategories />}
        />
        <Route
          path="/dynamite-event-categories/:event_id/add-category"
          element={<AddOrUpdateEventCategory />}
        />
        <Route
          path="/dynamite-event-categories/:event_id/edit-category/:category_slug"
          element={<AddOrUpdateEventCategory />}
        />
        <Route
          path="/dynamite-events/:event_id/members"
          element={<DynamiteEventMembers />}
        />
        <Route
          path="/dynamite-events/:event_id/add-members"
          element={<AddDynamiteEventMember />}
        />
        <Route
          path="/dynamite-events/:event_id/edit-member/:member_id"
          element={<EditDynamiteEventMember />}
        />
        <Route
          path="/dynamite-events/:dynamite_event_id/general-events"
          element={<GeneralEvents />}
        />
        <Route
          path="/dynamite-events/:dynamite_event_id/general-events/add-event"
          element={<AddOrUpdateGeneralEvents />}
        />
        <Route
          path="/dynamite-events/general-events/edit-event/:event_slug"
          element={<AddOrUpdateGeneralEvents />}
        />
        <Route
          path="/dynamite-event-videos/:category_id"
          element={<DynamiteEventVideoList />}
        />
        <Route
          path="/dynamite-event-videos/questions-configuration/:video_id"
          element={<QuestionConfigurationForEvent />}
        />
        <Route
          path="/dynamite-event-videos/video-questions"
          element={
            <GeneralQuestionsEvents
              created_for="dynamite_event_video"
              question_page_title="Dynamite Event Video Questions"
            />
          }
        />
        <Route
          path="/dynamite-event-videos/video-question/answers"
          element={
            <QuestionsAnswersAsCreatedFor created_for="dynamite_event_video" />
          }
        />
        <Route
          path="/dynamite-event-videos/video-question/answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/dynamite-event-videos/:category_id/add-video"
          element={<AddOrUpdateDynamiteEventVideo />}
        />
        <Route
          path="/dynamite-event-videos/:category_id/edit-video/:video_id"
          element={<AddOrUpdateDynamiteEventVideo />}
        />
        <Route
          path="/live-events/add-events/:event_slug"
          element={<AddOrUpdateDynamiteEvent />}
        />{" "}
        <Route
          path="/my-events/add-events/:event_slug"
          element={<AddOrUpdateMyDynamiteEvent />}
        />
        <Route path="/daily_activities" element={<DailyActivities />} />
        <Route
          path="/calendar-events"
          element={<NewCalendar heading="Events List" />}
        />
        <Route
          path="/calendar-events/add-event"
          element={<AddOrUpdateEvent />}
        />
        <Route
          path="/calendar-events/edit-event/:event_slug"
          element={<AddOrUpdateEvent />}
        />
        <Route
          path="/calendar-events/edit-iteration/:event_slug"
          element={<AddOrUpdateEvent />}
        />
        <Route
          path="/delegate-events/add-event"
          element={<AddOrUpdateDelegateEvent />}
        />
        <Route
          path="/delegate-events/edit-event/:event_slug"
          element={<AddOrUpdateDelegateEvent />}
        />
        <Route
          path="/delegate-events/edit-iteration/:event_slug"
          element={<AddOrUpdateDelegateEvent />}
        />
        <Route
          path="/delegate-events"
          element={
            <NewCalendar
              created_for="consultant_user"
              heading="Delegate Events"
            />
          }
        />
        <Route
          path="/calendar-events/event-list"
          element={<CalendarEventsList />}
        />
        <Route
          path="/calendar-events/event-detail/:event_slug"
          element={<EventDetailListNew />}
        />
        <Route
          path="/delegate-events/event-list"
          element={<CalendarEventsList created_for="consultant_user" />}
        />
        {/* <Route path="/calender/event-list" element={<ListEvents />} /> */}
        <Route path="/calender/event-list" element={<CalendarEventsList />} />
        <Route path="/calender/event-detail" element={<EventDetailList />} />
        <Route
          path="/calender/members/:event_slug/:iteration_id"
          element={<CalendarEventMembers />}
        />
        {/* <Route path="/support_ticket" element={<SupportTicket />} /> */}
        <Route
          path="/recordings/detail-recording"
          element={<RecordingDetails />}
        />
        <Route path="/pods/pod-detail" element={<RoomDetail />} />
        <Route
          path="/dynamite-pods/dynamite-pods-details/:pod_slug"
          element={<NewPodsRoomDetail />}
        />
        <Route
          path="/members/personel-notes/:id"
          element={<PersonalMemberNotes type="members" />}
        />{" "}
        <Route path="/booking/notes/:id" element={<BookingNotes />} />
        <Route
          path="/all-members/personel-notes/:id"
          element={<PersonalMemberNotes type="all_members" />}
        />
        <Route
          path="/leads-center/personel-notes/:id"
          element={<PersonalMemberNotes type="all_members" />}
        />
        <Route path="/subscription-list" element={<SubscriptionList />} />
        <Route path="/website-pages/:page_id/venues" element={<VenuesList />} />
        <Route
          path="/website-pages/:page_id/add-venues"
          element={<AddOrUpdateVenues />}
        />
        <Route
          path="/website-pages/:page_id/edit-venues/:success_id"
          element={<AddOrUpdateVenues />}
        />
        {/* banner slider */}
        <Route
          path="/website-pages/:page_id/banner-slider"
          element={<BannerSliderList />}
        />
        <Route
          path="/website-pages/:page_id/add-banner-slider"
          element={<AddBannerSlider />}
        />
        <Route
          path="/website-pages/:page_id/edit-banner-slider/:banner_id"
          element={<AddBannerSlider />}
        />
        {/* banner slider end */}
        <Route
          path="/website-pages/update-thanks-content/:id"
          element={<UpdateThanksContent />}
        />
        <Route
          path="/website-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        <Route path="/website-pages" element={<PagesList />} />
        <Route path="/appointment" element={<AddAppointment />} />
        <Route path="/appointment-configuration" element={<Appointment />} />
        <Route path="/website-links" element={<LinksTabs />} />
        <Route
          path="/website-pages/set-commission/:id"
          element={<CommissionPLans />}
        />{" "}
        <Route
          path="/website-links/set-commission/:id"
          element={<CommissionPLans />}
        />
        <Route
          path="/website-links/manage-subteam-access/:plan_id"
          element={<ManageSubteamAccess />}
        />
        <Route path="/website-pages/add-page" element={<AddOrUpdatePage />} />
        <Route path="/all-sales" element={<AllSales />} />
        <Route path="/all-leads/:id" element={<AllSalesPage />} />
        <Route path="/all-leads" element={<AllSalesPage />} />
        <Route
          path="/website-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/website-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        <Route
          path="/website-pages/:page_id/testimonials"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/website-pages/:page_id/benifits"
          element={<BenefitList />}
        />
        <Route
          path="/website-pages/:page_id/add-benefits"
          element={<AddOrUpdateBenefit />}
        />
        <Route
          path="/website-pages/:page_id/edit-benifits/:benefits_id"
          element={<AddOrUpdateBenefit />}
        />
        <Route
          path="/website-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/testimonials/edit-testimonial/:testimonial_id"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-pages/general-questions"
          element={<GeneralQuestions created_for="page" />}
        />
        <Route
          path="/website-pages/answers"
          element={<QuestionsAnswersAsCreatedFor created_for="page" />}
        />
        <Route
          path="/website-pages/answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes"
          element={<WebsiteProgrammesList />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes/add-programme"
          element={<AddOrUpdateProgramme />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes/edit-programme/:programme_slug"
          element={<AddOrUpdateProgramme />}
        />
        <Route
          path="/website-pages/:page_id/success-schedule"
          element={<SuccessScheduleList />}
        />
        <Route
          path="/website-pages/:page_id/success-schedule/add-schedule"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/website-pages/:page_id/success-schedule/edit-schedule/:schedule_id"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy"
          element={<BusinessStrategyList />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/add-business-strategy"
          element={<AddOrUpdateBusinessStrategy />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddOrUpdateBusinessStrategy />}
        />
        <Route
          path="/website-pages/:page_id/website-faq"
          element={<WebPageFaqs />}
        />
        <Route
          path="/website-pages/:page_id/website-faq/add-faq"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="website-pages/:page_id/website-faq/edit-faq/:faq_id"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id"
          element={<AgreementConfiguration />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id"
          element={<AgreementConfiguration />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/members/lead-status-history/:id"
          element={<LeadStatusHistory />}
        />{" "}
        <Route
          path="/nurture-members/lead-status-history/:id"
          element={<LeadStatusHistory />}
        />{" "}
        <Route
          path="/all-members/lead-status-history/:id"
          element={<LeadStatusHistory />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-program-access"
          element={<ProgrammeAccessPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-events-access"
          element={<EventAccessPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/questions"
          element={<QuestionSurvey />}
        />
        {/* /commission-detail */}
        <Route path="/commission-detail" element={<AffiliateInformation />} />
        <Route path="/video-series" element={<VideoSeries />} />
        <Route path="/progress" element={<Progress />} />
        {/* <Route path="/pages" element={<WebsitePages />} />
        <Route path="/pages/add-page" element={<AddPages />} />
        <Route path="/pages/edit-page" element={<EditPages />} />
        <Route path="/pages/:page_id/website-faq" element={<WebPageFaqs />} />
        <Route
          path="/pages/:page_id/website-faq/add-faq"
          element={<AddWebPageFaq />}
        />
        <Route
          path="pages/:page_id/website-faq/edit-faq/:id"
          element={<EditWebPageFaq />}
        />
        <Route
          path="/pages/sale-testimonial/:page_id"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/pages/sale-testimonial/add-sale-testimonial/:page_id"
          element={<AddWebPageTestimonial />}
        />
        <Route
          path="/pages/:page_id/edit-sale-testimonial/:id"
          element={<EditWebPageTestimonial />}
        />
        <Route path="/pages/payment-plans/:id" element={<PaymentPlansList />} />
        <Route
          path="/pages/payment-plans/add-payment-plan/:id"
          element={<AddPaymentPlan />}
        />
        <Route
          path="/pages/payment-plans/edit-payment-plan/:id"
          element={<EditPaymentPlan />}
        />
        <Route path="/pages/question/:page_id" element={<QuestionSurvey />} />
        <Route
          path="/pages/update-page-content/:id"
          element={<UpdatePageContent />}
        /> */}
        {/* live event Routs */}
        <Route path="/live-events" element={<LiveEvents />} />
        <Route
          path="/pending-commission"
          element={<PendingCommissions type="pending" />}
        />
        <Route
          path="/paid-commission"
          element={<PendingCommissions type="paid" />}
        />
        <Route path="/my-events" element={<MyLiveEvents />} />
        <Route
          path="/sale-team-transactions"
          element={<AffiliateTransactions />}
        />{" "}
        <Route path="/leads-center" element={<LeadsCenter />} />
        <Route
          path="/sale-team-transactions/add-transaction"
          element={<AddOrUpdateAffiliateTransactions />}
        />
        <Route
          path="/pending-commissions/add-transaction/:id"
          element={<AddOrUpdateAffiliateTransactions />}
        />
        <Route path="/live-events/:id" element={<LiveEventsDetail />} />
        <Route
          path="/live-events/:id/:category_slug"
          element={<LiveEventsDetail />}
        />
        <Route path="/my-events/:id" element={<LiveEventsDetail />} />
        <Route
          path="/my-events/:id/:category_slug"
          element={<LiveEventsDetail />}
        />
        <Route
          path="/live-events/:event_id/feed-detail/:post_id"
          element={<PostDetails feeds_type="event" list_type="event" />}
        />
        {/* <Route
          path="/live-events-locked/:id"
          element={<LiveEventsLockedDescription />}
        />
        <Route
          path="/live-events/video/question-answers/:created_for_id"
          element={<GeneralQuestionsList created_for="dynamite_event_video" />}
        />
        <Route
          path="/live-events/video/question-answers/:created_for_id/thank-you"
          element={<QuestionThankYou created_for="dynamite_event_video" />}
        />
        <Route
          path="/live-events/:id/:category_slug"
          element={<LiveEventsDetail />}
        />
        <Route
          path="/live-events/:event_id/video-detail/:video_id"
          element={<LiveEventVideoDetails />}
        />
        */}
        <Route
          path="/live-events/:event_id/video-detail/:video_id"
          element={<LiveEventVideoDetails />}
        />
        {/* live event Routs end */}
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-as-consultant" element={<LoginAsConsultant />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* <PublicRouteWithLayout component={Login} exact layout={LogoOnlyLayout} path="/login" />
      <PublicRouteWithLayout component={Register} exact layout={LogoOnlyLayout} path="/register" /> */}
      {/* --------------------------------------- Dashboard --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={DashboardApp}
        exact
        layout={DashboardLayout}
        path="/dashboard/app"
      /> */}
      {/* --------------------------------------- Change Password --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={Products}
        exact
        layout={DashboardLayout}
        path="/dashboard/products"
      /> */}
    </Routes>
  );
}
//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'products', element: <Products /> },
//         { path: 'blog', element: <Blog /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }
