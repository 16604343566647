import React, { useCallback, useEffect, useRef, useState } from "react";
import { get_feeds_detail, get_feeds_list } from "src/DAL/Community/Community";
import CreatePost from "./CreatePost";
import OpenCreatePostCard from "./OpenCreatePostCard";
import PostCard from "./PostCard";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FeedDetailPopup from "./FeedDetailPopup";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { COMMUNITY_LEVELS, show_proper_words } from "src/utils/constants";
import RecordNotFound from "src/components/RecordNotFound";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ChangeBookingStatus from "src/components/booking/ChangeBookingStatus";
import AddAsInternalNote from "./AddAsInternalNote";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { addPersonalNote } from "src/DAL/member/Member";
import { s3baseUrl } from "src/config/config";
import FeedPollResult from "./components/FeedPoll/FeedPollResult";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import SearchFilterFeeds from "./SeaarchFilter";
import FeedSurveyResult from "./components/SurveyFeed/FeedSurveyResult";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

function MainPart({
  event_id,
  feedSetting,
  list_type,
  is_scheduled,
  userType,
  handleChangeUserType,
  searchFeeds,
  setSearchFeeds,
  handleChangeSearch,
}) {
  const { socket, userInfo, isAccess } = useContentSetting();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [feedsList, setFeedsList] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const [feedType, setFeedType] = useState("general");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [personalNotePopUp, setpersonalNotePopUp] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [openInternalNote, setopenInternalNote] = useState(false);
  const [openPoll, setOpenPoll] = useState(false);
  const [openSurvey, setOpenSurvey] = useState(false);

  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totlePages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totlePages, pageNumber, loadMorePath, observer]
  );

  const handleCloseDetailBox = () => {
    setOpenDetailModal(false);
  };
  const handleSelectedImage = (post, index) => {
    setSelectedIndex(index);
    setSelectedPost(post);
    setOpenDetailModal(true);
  };

  const handleShowDetails = (post) => {
    setSelectedPost(post);
    setOpenDetailModal(true);
  };

  const handleOpenCreateBox = (type) => {
    setOpenCreateModal(true);
    setFeedType(type);
  };

  const handleSuccessDelete = (post) => {
    setOpenDetailModal(false);
    const updated_feed = feedsList.filter((feed) => feed._id !== post._id);
    setFeedsList(updated_feed);
  };

  const handleSuccessUpdate = async (post) => {
    handleUpdateSpecificFeed(post._id);
    setOpenDetailModal(false);
  };

  const handleUpdateSpecificFeed = async (id) => {
    const result = await get_feeds_detail(id);
    if (result.code === 200) {
      const data = feedsList.map((feed) => {
        if (feed._id === id) {
          let comment = result.feeds.comment_obj;
          if (!comment) {
            comment = [];
          }
          return { ...result.feeds, comment: comment };
        } else {
          return feed;
        }
      });

      setFeedsList(data);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleUpdateShowAll = async (id, val) => {
    const data = feedsList.map((feed) => {
      if (feed._id === id) {
        return { ...feed, is_show_all: val };
      } else {
        return feed;
      }
    });
    setFeedsList(data);
  };
  // -----------------------------------
  // ---------------------------
  const handleChangeStatus = async () => {
    let note = "";
    let imageString = "";
    let dis = "";
    if (
      selectedFeed.feed_type == "image" ||
      selectedFeed.feed_type == "general"
    ) {
      dis = `<p>${selectedFeed.description}</p>`;
      let img_array =
        selectedFeed.feed_images.length > 0 &&
        selectedFeed.feed_images.map((item, index) => {
          return `<img src=${`${s3baseUrl + item.thumbnail_1}`}>`;
        });
      if (img_array.length > 0) {
        imageString = img_array.join(" <br/>");
      }
      note = `${imageString ? imageString + "<br/>" + dis : dis}`;
    } else if (selectedFeed.feed_type == "video") {
      imageString = selectedFeed.video_url;
      dis = `<p>${selectedFeed.description}</p>`;
      note = `${imageString + "<br/><br/>" + dis}`;
    } else if (selectedFeed.feed_type == "embed_code") {
      imageString = selectedFeed.embed_code;
      dis = `<p>${selectedFeed.description}</p>`;
      note = `${imageString + "<br/><br/>" + dis}`;
    }
    let postData = {
      personal_note: note,
      member_id: selectedFeed.action_info.action_id,
      feed_id: selectedFeed._id,
    };
    setopenInternalNote(false);
    const result = await addPersonalNote(postData);
    if (result.code === 200) {
      setopenInternalNote(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setopenInternalNote(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ----------------------------------
  const getFeedsListing = async (loadMorePath) => {
    const result = await get_feeds_list(loadMorePath);
    if (result.code === 200) {
      const new_feeds = result.feeds.map((feed) => {
        return { ...feed, is_show_all: false };
      });
      var newArray = feedsList.concat(new_feeds);
      setFeedsList(newArray);
      setPageNumber(pageNumber + 1);
      setTotlePages(result?.total_pages);
      setLoadMorePath(result?.load_more);
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    } else {
      // enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
      setIsLoadingMore(false);
    }
  };

  const getFeedsListingOnAddFeed = async () => {
    if (is_scheduled) {
      list_type = "scheduled"; // want to send list type scheduled even it's scheduled posts inside the portals or outside in sidebar // backend requirement
    }
    setIsLoadingSection(true);
    let feed_action_type = searchFeeds.feed_action_type;
    let feed_action_by = "all";
    if (searchFeeds.feed_action_type == "own") {
      feed_action_by = userInfo._id;
    } else if (searchFeeds.feed_action_type == "other") {
      feed_action_by = searchFeeds?.feed_action_by
        ? searchFeeds?.feed_action_by?._id
        : "";
    }

    let path = `api/feeds/delegate_portal/feed_listing/v1?list_type=${list_type}&level_or_type=${userType}&feed_action_type=${feed_action_type}&feed_action_by=${feed_action_by}`;
    if (event_id) {
      path = path + `&event=${event_id}`;
    }
    const result = await get_feeds_list(path);
    if (result.code === 200) {
      setFeedsList(result.feeds);
      setPageNumber(1);
      setTotlePages(result?.total_pages);
      setLoadMorePath(result?.load_more);
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    } else {
      // enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
    }
  };

  const getFeedsListingOnDelete = async (id) => {
    const data = feedsList.filter((feed) => feed._id !== id);
    setFeedsList(data);
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getFeedsListing(loadMorePath);
  };

  const handleFeedEdit = (selected_feed) => {
    setSelectedFeed(selected_feed);
    setOpenCreateModal(true);
  };
  const handleINternalNote = (selected_feed) => {
    setSelectedFeed(selected_feed);
    setpersonalNotePopUp(true);
  };
  const handleINternalNoteClient = (selected_feed) => {
    setopenInternalNote(true);
    setSelectedFeed(selected_feed);
  };

  const update_feed_likes_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let set_feed = {
            ...feed,
            like_count: data.action_response.like_count,
            top_liked_user: data.action_response.top_liked_user,
            coins_count: data.action_response.coins_count,
          };
          if (userInfo._id == data.action_by) {
            set_feed.is_liked = data.action_response.is_liked;
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const update_comment_likes_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          if (feed.comment?.length > 0) {
            let action_comment = data.action_response.comment;
            let comments = feed.comment.map((comment) => {
              if (
                data.action_response.parent_comment &&
                comment?.child_comment
              ) {
                let child_comments = comment.child_comment.map((c_comment) => {
                  if (c_comment._id == action_comment) {
                    c_comment.like_count =
                      data.action_response.comment_like_count;
                    if (userInfo._id == data.action_by) {
                      c_comment.is_liked = data.action_response.is_liked;
                    }
                  }
                  return c_comment;
                });
                comment.child_comment = child_comments;
              } else {
                if (comment._id == action_comment) {
                  comment.like_count = data.action_response.comment_like_count;
                  if (userInfo._id == data.action_by) {
                    comment.is_liked = data.action_response.is_liked;
                  }
                }
              }
              return comment;
            });
            feed.comment = comments;
          }
        }
        return feed;
      });
      return feed_list;
    });
  };

  const add_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let new_comment = data.action_response.comment;
          if (userInfo._id == data.action_by) {
            new_comment.comment_is_self = true;
          }
          if (feed.comment?.length > 0) {
            feed.comment = [...feed.comment, new_comment];
          } else {
            feed.comment = [new_comment];
          }
          feed.comment_count = data.action_response.feed.comment_count;
        }
        return feed;
      });
      return feed_list;
    });
  };

  // const add_comment_reply_by_socket = (data) => {
  //   setFeedsList((feeds) => {
  //     return feeds.map((feed) => {
  //       if (feed._id === data.feed_id) {
  //         if (feed.comment?.length > 0) {
  //           let comments = feed.comment?.map((comment) => {
  //             if (comment._id == data.action_response.parent_comment) {
  //               let child_comments = [
  //                 ...comment.child_comment,
  //                 data.action_response.comment,
  //               ];
  //               comment.child_comment = child_comments.map((c_comment) => {
  //                 if (
  //                   userInfo._id == c_comment.user_info_action_for.action_id
  //                 ) {
  //                   c_comment.comment_is_self = true;
  //                 } else {
  //                   c_comment.comment_is_self = false;
  //                 }
  //                 return c_comment;
  //               });
  //             }
  //             return comment;
  //           });
  //           feed.comment = comments;
  //           feed.comment_count = data.action_response.feed.comment_count;
  //         }
  //       }
  //       return feed;
  //     });
  //   });
  // };
  const add_comment_reply_by_socket = (data) => {
    let new_comment = data.action_response.comment;
    if (userInfo._id == new_comment.user_info_action_for.action_id) {
      new_comment.comment_is_self = true;
    }

    setFeedsList((feeds) => {
      return feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          if (feed.comment?.length > 0) {
            let comments = feed.comment?.map((comment) => {
              if (comment._id == data.action_response.parent_comment) {
                let child_comments = comment.child_comment;
                let find_c = child_comments.find(
                  (c) => c._id == new_comment._id
                );
                if (!find_c) {
                  child_comments = [new_comment, ...comment.child_comment];
                  comment.child_comments_count++;
                }
                comment.child_comment = child_comments;
              }
              return comment;
            });
            feed.comment = comments;
            feed.comment_count = data.action_response.feed.comment_count;
          }
        }
        return feed;
      });
    });
  };

  const edit_comment_reply_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let comments = feed.comment;
          if (comments?.length > 0) {
            comments = comments.map((comment) => {
              let new_comment = data.action_response.comment;
              if (comment._id == new_comment.parent_comment) {
                let child_comments = comment.child_comment;
                if (child_comments?.length > 0) {
                  child_comments = child_comments.map((child_comment) => {
                    if (child_comment._id == data.action_response.comment._id) {
                      let is_liked = child_comment.is_liked;
                      child_comment = data.action_response.comment;
                      let comment_is_self = false;
                      if (userInfo._id == data.action_by) {
                        comment_is_self = true;
                      }
                      child_comment.comment_is_self = comment_is_self;
                      child_comment.is_liked = is_liked;
                    }
                    return child_comment;
                  });
                }
                comment.child_comment = child_comments;
              }
              return comment;
            });
          }
          feed.comment = comments;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const update_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let comments = feed.comment;
          if (comments?.length > 0) {
            comments = comments?.map((comment) => {
              let new_comment = data.action_response.comment;
              if (comment._id == new_comment._id) {
                let comment_is_self = false;
                if (userInfo._id == data.action_by) {
                  comment_is_self = true;
                }
                new_comment.comment_is_self = comment_is_self;
                new_comment.is_liked = comment.is_liked;
                comment = new_comment;
              }
              return comment;
            });
          }
          feed.comment = comments;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const delete_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id == data.feed_id && feed.comment.length > 0) {
          let comments = feed.comment.filter(
            (item) => item._id !== data.comment
          );
          feed.comment = comments;
          feed.comment_count = data.action_response.feed.comment_count;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const delete_comment_reply_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let comments = feed.comment;
          if (comments?.length > 0) {
            comments = comments.map((signle_comment) => {
              if (signle_comment?.child_comment?.length > 0) {
                const array_filtered = signle_comment.child_comment.filter(
                  (comment) => comment._id !== data.comment
                );
                signle_comment.child_comment = array_filtered;
              }
              return signle_comment;
            });
          }
          feed.comment = comments;
          feed.comment_count = data.action_response.feed.comment_count;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const handle_poll_answered = (data) => {
    console.log(data, "datadatadatadata");
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id == data.feed_obj._id) {
          feed.poll_info = data.feed_obj.poll_info;
          if (data.action_by._id === userInfo._id) {
            feed.selected_options = data.feed_obj.selected_options;
          }
        }
        return feed;
      });
      return feed_list;
    });
  };

  const handle_poll_expired = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        let find_feed = data.feeds.find((item) => item.feed_id === feed._id);
        if (find_feed) {
          feed.poll_info.poll_status = "expired";
        }
        return feed;
      });
      return feed_list;
    });
  };

  const handle_survey_answered = (data) => {
    console.log(data, "datadatadatadata");
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id == data.feed_obj._id) {
          feed.survey_info = data.feed_obj.survey_info;
          if (data.action_by._id === userInfo._id) {
            feed.survey_selected_options =
              data.feed_obj.survey_selected_options;
          }
        }
        return feed;
      });
      return feed_list;
    });
  };

  const handle_survey_expired = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        let find_feed = data.feeds.find((item) => item.feed_id === feed._id);
        if (find_feed) {
          feed.survey_info.survey_status = "expired";
        }
        return feed;
      });
      return feed_list;
    });
  };

  const handlePollDetail = (feed) => {
    setSelectedFeed(feed);
    setOpenPoll(true);
  };

  const handleSurveyDetail = (feed) => {
    setSelectedFeed(feed);
    setOpenSurvey(true);
  };

  const handleClose = () => {
    setSelectedFeed({});
    setOpenPoll(false);
    setOpenSurvey(false);
  };

  const handleUpdatePoll = (feed_obj) => {
    console.log(feed_obj, "feed_objfeed_objfeed_objfeed_obj");
    setFeedsList((old) =>
      old.map((item) => {
        if (item._id === feed_obj._id) {
          item = feed_obj;
        }
        return item;
      })
    );
  };
  const end_stream_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let set_feed = {};
          set_feed = {
            ...feed,
            is_live_streaming: false,
          };
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const handle_update_actions = (data) => {
    if (data.action === "feedlike" || data.action === "feedunlike") {
      update_feed_likes_by_socket(data);
    } else if (
      data.action === "commentlike" ||
      data.action === "commentunlike"
    ) {
      update_comment_likes_by_socket(data);
    } else if (data.action === "add_comment") {
      add_comment_by_socket(data);
    } else if (data.action === "edit_comment") {
      update_comment_by_socket(data);
    } else if (data.action === "delete_comment") {
      delete_comment_by_socket(data);
    } else if (data.action === "add_comment_reply") {
      add_comment_reply_by_socket(data);
    } else if (data.action === "edit_comment_reply") {
      edit_comment_reply_by_socket(data);
    } else if (data.action === "delete_comment_reply") {
      delete_comment_reply_by_socket(data);
    } else if (data.action === "poll_answered") {
      handle_poll_answered(data);
    } else if (data.action === "poll_expired") {
      handle_poll_expired(data);
    } else if (data.action === "survey_answered") {
      handle_survey_answered(data);
    } else if (data.action === "survey_expired") {
      handle_survey_expired(data);
    }
  };

  useEffect(() => {
    socket.emit("delegate_feed_room", "delegate_live_feed_room");
    socket.emit("live_event_room", "live_feed_room");

    socket.on("end_stream_event_listener", (data) => {
      end_stream_by_socket(data);
    });

    socket.on("live_feed_room_reciever", (data) => {
      handle_update_actions(data);
    });

    socket.on("delegate_live_feed_room_reciever", (data) => {
      handle_update_actions(data);
    });

    return () => {
      socket.off("delegate_live_feed_room_reciever");
      socket.off("live_feed_room_reciever");
    };
  }, [openDetailModal]);

  useEffect(() => {
    getFeedsListingOnAddFeed();
  }, [userType, list_type]);

  useEffect(() => {
    if (
      searchFeeds.feed_action_type == "all" ||
      searchFeeds.feed_action_type == "own"
    ) {
      getFeedsListingOnAddFeed();
    } else if (
      searchFeeds.feed_action_type == "other" &&
      searchFeeds.feed_action_by
    ) {
      getFeedsListingOnAddFeed();
    }
  }, [searchFeeds]);

  return (
    <>
      {/* userInfo.is_super_delegate  */}
      {window.screen.width <= 991 && list_type !== "event" && (
        <div className="thesource-filter my-3">
          {list_type == "the_cosmos" ? (
            isAccess.cosmos_feeds_filters && (
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-labelll">
                  Select Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-labelll"
                  id="demo-simple-select"
                  value={userType}
                  label="Select Level"
                  className="text-capitalize"
                  onChange={(e) => handleChangeUserType(e)}
                >
                  {isAccess.cosmos_feed_filters &&
                    isAccess.cosmos_feed_filters.length > 0 &&
                    isAccess.cosmos_feed_filters.map((item, index) => {
                      return (
                        <MenuItem value={item} className="text-capitalize">
                          {show_proper_words(
                            item == "marketing" ? "marketing team" : item
                          )}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )
          ) : (
            <>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-labelll">
                  Select Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-labelll"
                  id="demo-simple-select"
                  value={userType}
                  label="Select Level"
                  onChange={handleChangeUserType}
                >
                  {COMMUNITY_LEVELS.map((val) => (
                    <MenuItem value={val.name} key={val.name}>
                      {val.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(list_type == "all_source" || list_type == "the_source") && (
                <>
                  {isAccess.is_feed_search_allowed && (
                    <div className="thesource-filter mb-3 mt-3">
                      <SearchFilterFeeds
                        setSearchFeeds={setSearchFeeds}
                        searchFeeds={searchFeeds}
                        handleChangeSearch={handleChangeSearch}
                        list_type={list_type}
                        userType={userType}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      <OpenCreatePostCard handleOpenCreateBox={handleOpenCreateBox} />

      {isLoadingSection ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : feedsList.length < 1 ? (
        <RecordNotFound title="Posts" />
      ) : (
        feedsList.map((feeds_list, index) => {
          return (
            <PostCard
              feeds_list={feeds_list}
              getFeedsListingOnDelete={getFeedsListingOnDelete}
              handleShowDetails={handleShowDetails}
              handleSelectedImage={handleSelectedImage}
              handleFeedEdit={handleFeedEdit}
              handleINternalNote={handleINternalNote}
              handleINternalNoteClient={handleINternalNoteClient}
              handleUpdateSpecificFeed={handleUpdateSpecificFeed}
              handleUpdateShowAll={handleUpdateShowAll}
              feedSetting={feedSetting}
              list_type={list_type}
              event_id={event_id}
              handleUpdatePoll={handleUpdatePoll}
              handlePollDetail={handlePollDetail}
              handleSurveyDetail={handleSurveyDetail}
            />
          );
        })
      )}
      {!isLoadingSection && totlePages > pageNumber ? (
        <div className="col-12 text-center favourite-buttton-box">
          <button
            ref={lastBookElementRef}
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            id="load-more-feed"
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}

      {openCreateModal && (
        <CreatePost
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          setFeedType={setFeedType}
          feedType={feedType}
          getFeedsListing={getFeedsListingOnAddFeed}
          handleSuccessUpdate={handleSuccessUpdate}
          selectedFeed={selectedFeed}
          setSelectedFeed={setSelectedFeed}
          event_id={event_id}
          userType={userType}
          list_type={list_type}
          is_scheduled={is_scheduled}
        />
      )}
      <GeneralPopUpModel
        open={personalNotePopUp}
        setOpen={setpersonalNotePopUp}
        title={"Add As Personal Notes"}
        componentToPassDown={
          <AddAsInternalNote
            selectedObject={selectedFeed}
            setOpen={setpersonalNotePopUp}
          />
        }
      />
      <GeneralModelBox
        open={openPoll}
        setOpen={setOpenPoll}
        className="feed-poll-popup-modal"
        componentToPassDown={
          <FeedPollResult
            selectedFeed={selectedFeed}
            handleClosePoll={handleClose}
            openPoll={openPoll}
          />
        }
      />

      <GeneralModelBox
        open={openSurvey}
        setOpen={setOpenSurvey}
        className="feed-poll-popup-modal"
        componentToPassDown={
          <FeedSurveyResult
            selectedFeed={selectedFeed}
            handleCloseSurvey={handleClose}
            openSurvey={openSurvey}
          />
        }
      />

      <CustomConfirmation
        open={openInternalNote}
        setOpen={setopenInternalNote}
        title={`Are you sure you want to add as ${
          selectedFeed.action_info ? selectedFeed.action_info.name : ""
        }'s personal note ?`}
        handleAgree={handleChangeStatus}
      />

      <Modal
        open={openDetailModal}
        onClose={handleCloseDetailBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="feed-detail-popup"
      >
        <>
          <div className="row post-detail-box">
            <span
              className="cross-show-all-likes-model"
              onClick={handleCloseDetailBox}
            >
              x
            </span>
          </div>
          <FeedDetailPopup
            handleCloseDetailBox={handleCloseDetailBox}
            post_id={selectedPost._id}
            feed={selectedPost}
            handleINternalNoteClient={handleINternalNoteClient}
            getFeedsListingOnAddFeed={handleUpdateSpecificFeed}
            handleSuccessDelete={handleSuccessDelete}
            handleSuccessUpdate={handleSuccessUpdate}
            event_id={event_id}
            handleINternalNote={handleINternalNote}
            openDetailModal={openDetailModal}
            selectedImageIndex={selectedIndex}
            list_type={list_type}
          />
        </>
      </Modal>
    </>
  );
}

export default MainPart;
