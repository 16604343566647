import React, { createRef } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import faker from "faker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useEffect, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import {
  GetTrackerApi,
  deleteDiaryApi,
  get_intention_dates,
  move_to_tomorrow,
  updateDynamiteDiaryApi,
  update_all_data,
  update_reminder_time_api,
} from "src/DAL/Tracking/Tracking";
import moment from "moment";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { s3baseUrl } from "src/config/config";
import CustomPopover from "../CustomPopover";
import CustomConfirmation from "../GeneralComponents/CustomConfirmation";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import GeneralPopUpModel from "../GeneralComponents/GeneralPopUpModel";
import ContentAudioPlayer from "./ContentAudioPlayer";
import ImageBox from "../GeneralComponents/ImageBox";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  fonts: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
let intentions_array = [
  {
    _id: faker.datatype.uuid(),
    option: "",
    complete: false,
    is_moved_to_tomorrow: false,
  },
  {
    _id: faker.datatype.uuid(),
    option: "",
    complete: false,
    is_moved_to_tomorrow: false,
  },
  {
    _id: faker.datatype.uuid(),
    option: "",
    complete: false,
    is_moved_to_tomorrow: false,
  },
  {
    _id: faker.datatype.uuid(),
    option: "",
    complete: false,
    is_moved_to_tomorrow: false,
  },
];
const AccountabilityTracker = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(true);
  const { userInfo, adminTimeZone } = useContentSetting();
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [objectDataAvailable, setObjectDataAvailable] = useState(false);
  const [firstDiayDate, setFirstDiayDate] = useState(new Date());
  const [objectData, setObjectData] = useState(false);
  const [viewContent, setViewContent] = useState(false);
  const [contentData, setContentData] = useState(null);
  const [prevDiaries, setPrevDiaries] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setdata] = useState();
  const [loopCount, setLoopCount] = useState(0);
  const classes = useStyles();
  const myFunctionRef = useRef(null);
  const [imagePath, setImagePath] = useState("");
  const navigate = useNavigate();
  const [previews, setPreviews] = useState("");
  const [trackerArray, setTrackerArray] = useState([]);
  const [previews2, setPreviews2] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [previews3, setPreviews3] = useState("");
  const [isLooping, setIsLooping] = useState(false);
  const [isLoopingIndex, setIsLoopingIndex] = useState(0);
  const [audioRefs, setAudioRefs] = useState([]);
  const { state } = useLocation();
  const [addArray, setaddArray] = useState(intentions_array);
  const [inputs, setinputs] = useState({
    morning_time: null,
    evening_time: null,
    morning_days: [],
    evening_days: [],
    affirm: "",
    paridigm: false,
    goal: false,
    gratitude: false,
  });

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  console.log(trackerArray, "trackerArraytrackerArraytrackerArray");
  const handleChangeFromINtention = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };

  const handleChange = (newValue, name) => {
    setinputs((prevState) => {
      return {
        ...prevState,
        [name]: newValue?.$d,
      };
    });
  };

  const handleChangeInputs = (newValue, name) => {
    const value = newValue.target.value;
    setinputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  const handleAdd = () => {
    const addedElement = [
      ...addArray,
      {
        _id: faker.datatype.uuid(),
        option: "",
        complete: false,
        is_moved_to_tomorrow: false,
      },
    ];
    setaddArray(addedElement);
  };

  function getFirstErrorString(data) {
    for (const item of data) {
      if (
        item.is_required &&
        (!item.status || (Array.isArray(item.images) && item.images.length < 0))
      ) {
        console.log(item, "item");
        return `${item.statement} is required`;
      }
    }
    return null;
  }

  const handleUpdateReminderTime = async (e, name) => {
    e.preventDefault();
    let postData = {};

    if (name == "morning_reminder") {
      postData.daily_dynamite_morning_reminder_time = {
        time: moment(inputs.morning_time).format("HH:mm"),
        days: inputs.morning_days,
      };
    } else {
      postData.daily_dynamite_evening_reminder_time = {
        time: moment(inputs.evening_time).format("HH:mm"),
        days: inputs.evening_days,
      };
    }

    const result = await update_reminder_time_api(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeDate = (value) => {
    setCalenderDate(value.$d);
  };

  const handleUpdateAllData = async (e, intention) => {
    if (e !== "checked") {
      e.preventDefault();
    }
    if (!intention) {
      const errorMessage = getFirstErrorString(trackerArray);
      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: "error" });
        return;
      }
    }

    if (e == "checked") {
      let postData = {
        date: calenderDate
          ? moment(calenderDate).format("DD-MM-YYYY")
          : moment(new Date()).format("DD-MM-YYYY"),
        note: inputs.affirm,
        tracker_intention: trackerArray,
        goal_statement_info: { status: inputs.goal, image: previews },
        gratitude_info: { status: inputs.gratitude, image: previews2 },
        paradigm_info: { status: inputs.paridigm, image: previews3 },
        statement_array: addArray,
      };

      const result = await update_all_data(postData);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        if (!objectDataAvailable) {
          GetIntentionsData();
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      e.preventDefault();

      let postData = {
        date: calenderDate
          ? moment(calenderDate).format("DD-MM-YYYY")
          : moment(new Date()).format("DD-MM-YYYY"),
        note: inputs.affirm,
        tracker_intention: trackerArray,
        goal_statement_info: { status: inputs.goal, image: previews },
        gratitude_info: { status: inputs.gratitude, image: previews2 },
        paradigm_info: { status: inputs.paridigm, image: previews3 },
        statement_array: addArray,
      };

      const result = await update_all_data(postData);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        if (!objectDataAvailable) {
          GetIntentionsData();
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };
  const handleDeleteDiary = async (value) => {
    const result = await deleteDiaryApi(deleteValue._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      GetIntentionsData();
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };
  const GetIntentionsData = async (date) => {
    if (!objectDataAvailable) {
      setIsLoading(true);
    }
    const result = await get_intention_dates(
      date
        ? moment(date).format("DD-MM-YYYY")
        : calenderDate
        ? moment(calenderDate).format("DD-MM-YYYY")
        : moment(new Date()).format("DD-MM-YYYY")
    );
    if (result.code == 200) {
      setdata(result?.delegate_report_setting);
      if (
        result.daily_dynamite.tracker_intention &&
        result.daily_dynamite.tracker_intention.length > 0
      ) {
        let trackerArray = result.daily_dynamite.tracker_intention;
        trackerArray.forEach((obj) => {
          obj.is_loop = false;
        });
        setTrackerArray(trackerArray);
      } else {
        let trackerArray = result?.delegate_report_setting.tracker_intentions;
        trackerArray.forEach((obj) => {
          obj.is_loop = false;
        });
        setTrackerArray(trackerArray);
      }
      setObjectDataAvailable(result.daily_dynamite._id ? true : false);
      setPrevDiaries(result.past_activities);

      setObjectData(result.daily_dynamite);
      setFirstDiayDate(
        result.first_daily_dynamite_date
          ? result.first_daily_dynamite_date
          : new Date()
      );
      setinputs((inputs) => ({
        ...inputs,
        ["morning_time"]: result.daily_dynamite_morning_reminder_time
          ? result.daily_dynamite_morning_reminder_time.time
          : null,
        ["evening_time"]: result.daily_dynamite_evening_reminder_time
          ? result.daily_dynamite_evening_reminder_time.time
          : null,
        ["affirm"]: result.daily_dynamite.note,
        ["goal"]: result.daily_dynamite.goal_statement_info
          ? result.daily_dynamite.goal_statement_info.status
          : false,
        ["paridigm"]: result.daily_dynamite.paradigm_info
          ? result.daily_dynamite.paradigm_info.status
          : false,
        ["gratitude"]: result.daily_dynamite.gratitude_info
          ? result.daily_dynamite.gratitude_info.status
          : false,

        ["morning_days"]: result.daily_dynamite_morning_reminder_time.days
          ? result.daily_dynamite_morning_reminder_time.days
          : [],
        ["evening_days"]: result.daily_dynamite_evening_reminder_time.days
          ? result.daily_dynamite_evening_reminder_time.days
          : [],
      }));
      setPreviews(
        result.daily_dynamite.goal_statement_info
          ? result.daily_dynamite.goal_statement_info.image
          : ""
      );

      setPreviews2(
        result.daily_dynamite.gratitude_info
          ? result.daily_dynamite.gratitude_info.image
          : ""
      );
      setPreviews3(
        result.daily_dynamite.paradigm_info
          ? result.daily_dynamite.paradigm_info.image
          : ""
      );
      if (
        result.daily_dynamite.statement_array &&
        result.daily_dynamite.statement_array.length > 0
      ) {
        setaddArray(result.daily_dynamite.statement_array);
      } else {
        setaddArray([
          {
            _id: faker.datatype.uuid(),
            option: "",
            complete: false,
            is_moved_to_tomorrow: false,
          },
          {
            _id: faker.datatype.uuid(),
            option: "",
            complete: false,
            is_moved_to_tomorrow: false,
          },
          {
            _id: faker.datatype.uuid(),
            option: "",
            complete: false,
            is_moved_to_tomorrow: false,
          },
        ]);
      }
      window.history.replaceState({}, document.title);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangeCheckBox = async (data, index) => {
    const list = [...addArray];
    list[index]["complete"] = data?.complete ? !data.complete : true;
    setaddArray(list);
    objectDataAvailable == true
      ? handleUpdateExist("checked", true)
      : handleUpdateAllData("checked", true);
  };
  const handleUpdateExist = async (e, intention) => {
    if (e !== "checked") {
      e.preventDefault();
    } else {
    }
    if (!intention) {
      const errorMessage = getFirstErrorString(trackerArray);
      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: "error" });
        return;
      }
    }
    if (e == "checked") {
      let complete_intentions = addArray.filter(
        (value) => value.complete == true
      );
      let incomplete_intentions = addArray.filter(
        (value) => value.complete == false
      );
      let postData = {
        date: calenderDate
          ? moment(calenderDate).format("DD-MM-YYYY")
          : moment(new Date()).format("DD-MM-YYYY"),
        note: inputs.affirm,

        tracker_intention: trackerArray,
        statement_array: addArray,
        completed_intention: complete_intentions?.length,
        incomplete_intention: incomplete_intentions?.length,
      };
      const result = await updateDynamiteDiaryApi(objectData._id, postData);

      if (result.code == 200) {
        enqueueSnackbar(result.message, {
          variant: "success",
        });
        if (!objectDataAvailable) {
          GetIntentionsData();
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      e.preventDefault();

      let complete_intentions = addArray.filter(
        (value) => value.complete == true
      );
      let incomplete_intentions = addArray.filter(
        (value) => value.complete == false
      );
      let postData = {
        date: calenderDate
          ? moment(calenderDate).format("DD-MM-YYYY")
          : moment(new Date()).format("DD-MM-YYYY"),
        note: inputs.affirm,
        tracker_intention: trackerArray,
        statement_array: addArray,
        completed_intention: complete_intentions?.length,
        incomplete_intention: incomplete_intentions?.length,
      };
      const result = await updateDynamiteDiaryApi(objectData._id, postData);

      if (result.code == 200) {
        enqueueSnackbar(result.message, {
          variant: "success",
        });
        GetIntentionsData();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };
  const handleClickTomorrow = async (value, index) => {
    if (!value.is_moved_to_tomorrow) {
      const existingDate = calenderDate;
      const dateObject = moment(existingDate, "YYYY-MM-DD");
      const futureDate = dateObject.add(1, "day").format("YYYY-MM-DD");
      delete value.is_moved_to_tomorrow;
      let data = {
        date: futureDate,
        intention_object: {
          _id: value._id,
          option: value.option,
          complete: false,
          is_moved_to_tomorrow: false,
        },
      };
      let add_array = [...addArray];
      let selected_obj = add_array[index];
      selected_obj.is_moved_to_tomorrow = true;
      add_array[index] = selected_obj;
      setaddArray(add_array);

      const result = await move_to_tomorrow(data);
      if (result.code == 200) {
        console.log(objectDataAvailable, "objectDataAvailable");
        objectDataAvailable == true
          ? handleUpdateExist("checked", true)
          : handleUpdateAllData("checked", true);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };
  const GetTrackerApiList = async () => {
    setIsLoading(true);
    const result = await GetTrackerApi();
    if (result.code == 200) {
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (value) => {
    setCalenderDate(new Date(value.date_time));

    myFunctionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleToogleLoop = (index) => {
    const list = [...trackerArray];
    let is_loop = !list[index].is_loop;
    list[index]["is_loop"] = is_loop;
    setTrackerArray(list);
    setIsLoopingIndex(index);

    const audioElement = audioRefs[index].current;
    if (audioElement) {
      setIsLooping(!is_loop); // Update looping state
      audioElement.loop = !is_loop; // Set audio loop
      audioElement.play(); // Play audio
    }
  };

  const handleViewContent = (data) => {
    setContentData(data);
    setViewContent(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const handleNavigate = (e, index) => {
    navigate(`/tracker/past-activities`);
  };
  // -------------------------------------------
  const handleInputChange = (e, index) => {
    const { name, checked } = e.target;
    const list = [...trackerArray];
    list[index][name] = checked;
    setTrackerArray(list);
  };
  const handleRemove = (parentIndex, index) => {
    console.log(index, "index");
    const list = [...trackerArray];
    list[parentIndex]["images"].splice(index, 1);
    console.log(list[parentIndex]["images"]);
    setTrackerArray(list);
  };
  const fileChangedHandler = async (e, index) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "2200");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      const list = [...trackerArray];
      if (!list[index]["images"]) {
        list[index]["images"] = [];
      }
      list[index]["images"].push(imageUpload.image_path);
      setTrackerArray(list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleInputChangeIntentions = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value, "texttt");
    const list = [...trackerArray];
    list[index][name] = value;
    setTrackerArray(list);
  };
  const handleClose = () => {
    setImagePath("");
  };
  // -------------------------------------------------------

  useEffect(() => {
    GetIntentionsData();
  }, [calenderDate]);

  useEffect(() => {
    if (state) {
      GetIntentionsData(new Date(state.date_time));
      setCalenderDate(new Date(state.date_time));
    }
  }, [state]);

  useEffect(() => {
    const audioElement = audioRefs[isLoopingIndex]?.current;

    const handleEnded = () => {
      setLoopCount(loopCount + 1);
      const list = [...trackerArray];
      let counts = list[isLoopingIndex].listen_count + 1;
      list[isLoopingIndex]["listen_count"] = counts;
      setTrackerArray(list);
      audioElement?.play();
    };

    audioElement?.addEventListener("ended", handleEnded);
    return () => {
      audioElement?.removeEventListener("ended", handleEnded);
    };
  }, [isLooping, loopCount, isLoopingIndex]);

  useEffect(() => {
    const refs = trackerArray.map(() => createRef());
    setAudioRefs(refs);
  }, [trackerArray]);

  useEffect(() => {
    if (viewContent === false) {
      setContentData(null);
    }
  }, [viewContent]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDeleteDiary}
      />
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h2
              style={{
                padding: "0px",
                color: "#f6bd4b",
                marginTop: "10px",
              }}
            >
              {data?.main_heading}
            </h2>
          </div>
          <div className="col-lg-6 col-md-6  mt-3">
            <div className="card " style={{ padding: "10px" }}>
              <h2
                className="mt-2 ms-1"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",

                  color: "white",
                }}
              >
                {data?.morning_heading}
              </h2>
              <form
                onSubmit={(e) => {
                  handleUpdateReminderTime(e, "morning_reminder");
                }}
              >
                <div className="mt-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Reminder Time"
                      value={inputs.morning_time}
                      onChange={(e) => {
                        handleChange(e, "morning_time");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required={true} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="mt-3">
                  <FormControl variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select Day
                    </InputLabel>
                    <Select
                      multiple
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={inputs.morning_days}
                      onChange={(e) => handleChangeInputs(e, "morning_days")}
                      label="Select Day"
                      name="days"
                    >
                      <MenuItem value={0}>Sunday</MenuItem>
                      <MenuItem value={1}>Monday</MenuItem>
                      <MenuItem value={2}>Tuesday</MenuItem>
                      <MenuItem value={3}>Wednesday</MenuItem>
                      <MenuItem value={4}>Thursday</MenuItem>
                      <MenuItem value={5}>Friday</MenuItem>
                      <MenuItem value={6}>Saturday</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="mt-3">
                  <span className="float-end">
                    <button className="submit-button">Save</button>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div className=" ps-0 col-lg-6 col-md-6  mt-3">
            <div className="card" style={{ padding: "10px" }}>
              <h2
                className="mt-2 ms-1"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",

                  color: "white",
                }}
              >
                {data?.evening_heading}
              </h2>
              <form
                onSubmit={(e) => {
                  handleUpdateReminderTime(e, "morninasda");
                }}
              >
                <div className="mt-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Reminder Time"
                      value={inputs.evening_time}
                      onChange={(e) => {
                        handleChange(e, "evening_time");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required={true} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="mt-3">
                  <FormControl variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select Day
                    </InputLabel>
                    <Select
                      multiple
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={inputs.evening_days}
                      onChange={(e) => handleChangeInputs(e, "evening_days")}
                      label="Select Day"
                      name="days"
                    >
                      <MenuItem value={0}>Sunday</MenuItem>
                      <MenuItem value={1}>Monday</MenuItem>
                      <MenuItem value={2}>Tuesday</MenuItem>
                      <MenuItem value={3}>Wednesday</MenuItem>
                      <MenuItem value={4}>Thursday</MenuItem>
                      <MenuItem value={5}>Friday</MenuItem>
                      <MenuItem value={6}>Saturday</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="mt-3">
                  <span className="float-end">
                    <button className="submit-button">Save</button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-12 mt-4">
          <p className="afrim mb-0">{data?.affirm_title}</p>
          <div className="mt-1">
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder={data?.affirm_placeHolder}
              className="text-color textarea-autosize"
              required
              value={inputs.affirm}
              onChange={(e) => handleChangeInputs(e, "affirm")}
              minRows={4}
              name="option"
              style={{
                width: "100%",
                borderRadius: "11px",
                background: "#1D2537",
                paddingTop: "10px",
                paddingBottom: "10px",
                height: "150px",
                paddingLeft: "15px",
                color: "white",
                fontSize: "15px",
                border: "2px solid rgba(71, 66, 34, 0.3)",
              }}
            />
          </div>
        </div>
      </div>
      <form
        onSubmit={
          objectDataAvailable == true ? handleUpdateExist : handleUpdateAllData
        }
      >
        <div className="container mt-3" ref={myFunctionRef}>
          <div className="row">
            <h2
              className="mt-2 ms-1"
              style={{
                fontSize: "20px",
                fontWeight: "600",

                color: "white",
              }}
            >
              {data?.intentions_heading}
            </h2>
            <div className="col-lg-7 col-md-7 mt-3">
              {addArray?.map((data, index) => {
                return (
                  <div className="d-flex position-relative mt-1">
                    <div className="mt-2 field-container">
                      <TextareaAutosize
                        aria-label="empty textarea"
                        placeholder={`${index + 1}.`}
                        className="text-color textarea-autosize"
                        required
                        value={data.option}
                        minRows={1}
                        name="option"
                        onChange={(e) => handleChangeFromINtention(e, index)}
                        style={{
                          width: "100%",
                          borderRadius: "11px",
                          background: "#1D2537",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingLeft: "15px",
                          color: "white",
                          fontSize: "15px",
                          border: "2px solid rgba(71, 66, 34, 0.3)",
                        }}
                      />
                      {data.option && (
                        <div className="text-end intentionCheckox d-flex justify-content-between on-mobile">
                          <div>
                            <Checkbox
                              checked={Boolean(data.is_moved_to_tomorrow)}
                              onClick={(e) => handleClickTomorrow(data, index)}
                              inputProps={{ "aria-label": "controlled" }}
                              style={{ padding: "2px" }}
                            />

                            {data.is_moved_to_tomorrow == true ? (
                              <span className="pt-2 cursor mobile-screen-text">
                                Moved to Tomorrow
                              </span>
                            ) : (
                              <span className="pt-2">Move to Tomorrow</span>
                            )}
                          </div>
                          <div>
                            <Checkbox
                              checked={data?.complete}
                              onClick={(e) => handleChangeCheckBox(data, index)}
                              inputProps={{ "aria-label": "controlled" }}
                              style={{ padding: "2px" }}
                            />

                            {data.complete == true ? (
                              <span className="pt-2">Intention Completed</span>
                            ) : (
                              <span className="pt-2">
                                Mark Intention Complete
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <span className="cross-icon new-icon cross-daily">
                      <Tooltip title="Add">
                        <AddCircleOutlineIcon
                          onClick={() => handleAdd()}
                          className="diary-icon-add"
                        />
                      </Tooltip>
                      {addArray.length > 1 ? (
                        <Tooltip title="Remove">
                          <RemoveCircleOutlineIcon
                            onClick={() => handleDelete(index)}
                            className="diary-icon-remove"
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className=" top-section-video col-lg-5 col-sm-5 mt-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  className="static-date-picker"
                  value={calenderDate}
                  onChange={(newValue) => handleChangeDate(newValue)}
                  minDate={firstDiayDate ? firstDiayDate : new Date()}
                  maxDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                  componentsProps={{
                    actionBar: {
                      actions: ["today"],
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row">
            {" "}
            {trackerArray.length > 0 && (
              <h2
                className="mt-2 ms-1"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                {data?.completed_heading}
              </h2>
            )}
            <div className="col-lg-12 ">
              {trackerArray.map((item, index) => {
                return (
                  <div className="tracking-intention">
                    <div
                      className="d-flex justify-content-between mt-3"
                      style={{ alignItems: "end", flexWrap: "wrap" }}
                    >
                      <div>
                        <p
                          className="mb-0"
                          style={{ fontSize: "17px", width: "100%" }}
                        >
                          <Checkbox
                            onChange={(e) => handleInputChange(e, index)}
                            checked={item.status}
                            name="status"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          {item.statement}
                        </p>
                        <div className="d-flex ms-3">
                          {item.images &&
                            item.images.length > 0 &&
                            item.images.map((item, ChildIndex) => {
                              return (
                                <>
                                  <div className="preview">
                                    <span
                                      className="track-image-cross"
                                      onClick={() =>
                                        handleRemove(index, ChildIndex)
                                      }
                                    >
                                      x
                                    </span>
                                    {item && (
                                      <>
                                        <Tooltip title="View Image">
                                          <img
                                            src={s3baseUrl + item}
                                            style={{
                                              // padding: "10px",
                                              width: "120px",
                                              height: "auto",
                                              cursor: "pointer",
                                              objectFit: "cover",
                                              aspectRatio: "3 / 2",
                                            }}
                                            className="main"
                                            onClick={() =>
                                              setImagePath(s3baseUrl + item)
                                            }
                                          />
                                        </Tooltip>
                                      </>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>

                      <div
                        className="width text-end d-flex"
                        style={{
                          alignItems: "end",
                          justifyContent: "end",
                        }}
                      >
                        <Tooltip
                          title={`${
                            item.status
                              ? `Upload Image`
                              : "Please tick the check box before Uploading Image"
                          }`}
                        >
                          <span
                            className={`${
                              item.status
                                ? "upload-button"
                                : "upload-button-cross"
                            } mb-1`}
                          >
                            <input
                              color="primary"
                              accept="image/*,.pdf,.xlsx,.xls,.docx,.csv,.doc"
                              type="file"
                              id={`icon-button-file ${222 + index}`}
                              style={{ display: "none" }}
                              disabled={!item.status}
                              name="goal"
                              onChange={(e) => fileChangedHandler(e, index)}
                              multiple={true}
                            />
                            <label
                              htmlFor={`icon-button-file ${222 + index}`}
                              className=""
                              style={{
                                cursor: item.status ? "pointer" : "not-allowed",
                              }}
                            >
                              <CloudUploadIcon />
                            </label>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <hr className="mt-1" />

                    {item.content_type == "audio" ? (
                      <ContentAudioPlayer
                        item={item}
                        calenderDate={calenderDate}
                      />
                    ) : (
                      <>
                        {item.content && (
                          <span
                            className="view-content"
                            onClick={() => handleViewContent(item)}
                          >
                            View Content
                          </span>
                        )}
                      </>
                    )}
                    {item.is_text_box_shown && (
                      <div className="col-lg-12 mt-3">
                        <div className="mt-1">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder={"write comments here"}
                            className="text-color textarea-autosize"
                            required
                            value={item.text_box_content}
                            onChange={(e) =>
                              handleInputChangeIntentions(e, index)
                            }
                            minRows={2}
                            name="text_box_content"
                            style={{
                              width: "100%",
                              borderRadius: "11px",
                              background: "#1D2537",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              height: "100px",
                              paddingLeft: "15px",
                              color: "white",
                              fontSize: "15px",
                              border: "2px solid rgb(71 66 34 / 55%)",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="mt-5">
              <span className="float-end">
                <button className="submit-button">{"Save"}</button>
              </span>
            </div>
          </div>
        </div>
      </form>
      <div>
        {prevDiaries.length > 0 ? (
          <div className="container dynamite-diary mt-5">
            <div className="row">
              <div className="recent col-lg-12 d-flex  justify-content-end me-2 pe-3">
                <p>Most Recent</p>
              </div>
              {prevDiaries?.map((value) => {
                return (
                  <div className="col-lg-6 mb-3" style={{ cursor: "pointer" }}>
                    <div className="mini-cards d-flex">
                      <div className="w-100 ms-2 me-2">
                        <div className="diary-font-weight d-flex">
                          <h4 onClick={() => handleEdit(value)}>
                            {value?.date ? value?.date : ""}
                          </h4>
                          <div className="d-flex">
                            <span
                              style={{ paddingTop: "2px" }}
                              onClick={() => handleEdit(value)}
                              className="diary-first-heading pe-2"
                            >
                              {value?.date_time
                                ? TIME_ZONE_CONVERSION(
                                    value.date_time,
                                    "hh:mm A",
                                    userInfo.time_zone,
                                    adminTimeZone
                                  )
                                : ""}
                            </span>
                            <CustomPopover menu={MENU_OPTIONS} data={value} />
                          </div>
                        </div>

                        <div className="d-flex justify-content-between ml-3 mt-2 diary-first-heading">
                          <div onClick={() => handleEdit(value)}>
                            <h4 style={{ fontSize: "14px" }}>
                              {value.statement_array[0]?.option}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <button
                  className="small-contained-button float-end mt-1 mb-4"
                  onClick={handleNavigate}
                >
                  Past Activities
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {imagePath && (
        <ImageBox open={true} handleClose={handleClose} image_url={imagePath} />
      )}
      <GeneralPopUpModel
        open={viewContent}
        setOpen={setViewContent}
        title={"Helping Content"}
        componentToPassDown={
          <div
            className="dashboard_description mt-3"
            dangerouslySetInnerHTML={{
              __html: contentData?.content,
            }}
          ></div>
        }
      />
    </>
  );
};

export default AccountabilityTracker;
