import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
// import {
//   DeleteCommentApi,
//   memberGoalStatementListApi,
// } from "src/DAL/member/member";
import { useLocation, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Switch,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
// import AddKimsReply from "./AddKimReply";
import { project_name } from "../../config/config";
import TextField from "@mui/material/TextField";
import GoalGallerySlider from "../MemberGoalStatement/GoalGallerySlider";
import CustomDrawer from "src/components/CustomDrawer/CustomDrawer";
import { useState } from "react";
import { AnswerHistory } from "../MemberGoalStatement/AnswerHistory";
import { QuestionsReply } from "../MemberGoalStatement/QuestionsReply";
import {
  getGoalStatementQuestion,
  questionDetailApi,
  DeleteCommentApi,
} from "src/DAL/GoalStatement/GoalStatement";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DeleteSelfImageReplyApi,
  get_history_detail_api,
  selfImageDetailApi,
  selfImageRepliesShowApi,
} from "src/DAL/SelfImageMember/SelfImageMember";
import { htmlDecode } from "src/utils/convertHtml";
import GeneralQuestionStarRating from "src/components/GeneralComponents/GeneralQuestions/GeneralQuestionStarRating";
import { SelfImageQuestionsReply } from "./SelfImageQuestionsReply";
import RecordNotFound from "src/components/RecordNotFound";
import AddKimsReply from "./AddKimReply";
import SlefImageReplies from "./SlefImageReplies";
import { useEffect } from "react";
import { useRef } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const SelfImageDetail = ({ type }) => {
  const params = useParams();
  const classes = useStyles();
  const titleRef = useRef();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [gallery, setGallery] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [replies, setReplies] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openAnswerHistory, setOpenAnswerHistory] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [answerHistoryData, setAnswerHistoryData] = useState();
  const [replyData, setReplyData] = useState();
  const [expanded, setExpanded] = useState(0);
  const [userInfo, setUserInfo] = useState("");

  const handleClick = () => {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChange = async (event, val) => {
    let postData = {
      member_id: state.member_id,
      created_for: "self_image",
      question_id: val._id,
      show_replies: event.target.checked,
    };
    let question_Array = [...questionsList];
    const updatedData = question_Array.map((item) => {
      if (item._id === val._id) {
        return {
          ...item,
          answer: {
            ...item.answer,
            show_replies: event.target.checked,
          },
        };
      }
      return item;
    });

    const result = await selfImageRepliesShowApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    }
    setQuestionsList(updatedData);
  };

  const handleOpenAnswerDrawer = (value) => {
    setAnswerHistoryData(value);
    setOpenAnswerHistory(true);
  };

  const handleCloseAnswerDrawer = () => {
    setOpenAnswerHistory(false);
  };
  const handleOpenReplyDrawer = (value) => {
    setReplyData(value);
    setOpenReply(true);
  };
  const handleCloseReplyDrawer = () => {
    setOpenReply(false);
  };

  const detailQuestionHistory = async (value) => {
    if (value == "reload") {
    } else {
      setIsLoading(true);
    }
    let postData = {
      created_for_id: "",
      member_id: params.id,
      created_for: "self_image",
      check_user: "true",
    };

    let result = {};
    if (type === "responded") {
      result = await get_history_detail_api(params.id);
    } else {
      result = await selfImageDetailApi(postData);
    }
    if (result.code == 200) {
      // setGallery(result.goal_statement_gallery);
      setQuestions(result.questionnaire);
      setReplies(result?.self_image_replies);

      // let galleryArray = [];
      // let galleryObject = {};
      // result.goal_statement_gallery.map((gallery, index) => {
      //   galleryObject = {
      //     original: s3baseUrl + gallery.thumbnail_1,
      //     thumbnail: s3baseUrl + gallery.thumbnail_2,
      //   };
      //   galleryArray.push(galleryObject);
      // });

      // setGallery(galleryArray);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleDeleteReply = (value) => {};

  const expandArea = (index) => {
    let temp_state = [...questions];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestions(temp_state);
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    let postData = {
      message_id: deleteDoc._id,
      created_for: "self_image",
    };
    const result = await DeleteSelfImageReplyApi(postData);
    if (result.code === 200) {
      detailQuestionHistory();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleReply = (value) => {
    navigate(`/member-goalstatement/question-reply/${params.id}`, {
      state: value,
    });
  };
  const handleAnswerHistory = (value) => {
    navigate(`/member-goalstatement/answer-history/${params.id}`, {
      state: value,
    });
  };

  useEffect(() => {
    detailQuestionHistory();
  }, []);

  useEffect(() => {
    const userName = JSON.parse(localStorage.getItem("user_data"));
    setUserInfo(userName);
  }, []);

  useEffect(() => {
    const updatedData = questions.map((item) => ({
      ...item,
      show_replies: item?.show_replies ? item?.show_replies : false,
    }));
    setQuestionsList(questions);
  }, [questions]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="row">
        <div className="col-12 mb-3 reply-anchor">
          <IconButton
            className="back-screen-button mb-4 me-3"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>

          {state?.name && state?.name + " (" + state?.email + ")"}
          {type !== "responded" && (
            <a
              className="small-contained-button float-end mt-1 anchor-style"
              // onClick={() => navigate(`/programmes/addreview/`)}
              onClick={handleClick}
            >
              {/* {userInfo?.first_name && userInfo.first_name + "'s" + " Reply"} */}
              {"Dynamite's" + " Reply"}
            </a>
          )}
        </div>
      </div>
      <div className="row justify-content-center pb-5">
        <div className="col-12 right-part mt-2">
          <div className="col-12">
            {questionsList?.length > 0 ? (
              questionsList.map((question, i) => {
                console.log(question, "questionquestionquestionquestion");
                return (
                  <div className="card mb-2" key={question._id}>
                    <Accordion
                      expanded={question.isExpanded}
                      className="qustion-section-accordion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() => expandArea(i)}
                        aria-controls="panel1a-content"
                        className="accordion-summary qustion-section-summary"
                      >
                        {/* <Typography>
                          {htmlDecode(question.question_statement)}
                        </Typography> */}
                        <h3>Question Statement</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.question_statement,
                          }}
                          className="question-answer"
                        ></div>
                      </AccordionSummary>

                      <AccordionDetails>
                        {question.question_type == "mcq" ? (
                          <FormControl style={{ width: "100%" }}>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="radio-buttons-group"
                              value={question.answer?.answer_statement}
                            >
                              {question?.options.map((option, index) => (
                                <div
                                  className={`question-answer  ${
                                    question.answer?.answer_statement
                                      ? option ===
                                        question.answer?.answer_statement
                                        ? "selectedAnswer"
                                        : ""
                                      : ""
                                  }`}
                                  key={option.id}
                                >
                                  <FormControlLabel
                                    value={option}
                                    id={`capsule${index}`}
                                    className="answer"
                                    control={<Radio disabled />}
                                    label={option}
                                    disabled
                                  />
                                </div>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        ) : question.question_type == "checkbox" ? (
                          <FormControl style={{ width: "100%" }}>
                            <FormGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="radio-buttons-group"
                              value={question?.answer}
                            >
                              {question?.options.map((option, index) => {
                                let checkbox_answer =
                                  question?.answer?.answer_statement &&
                                  question?.answer?.answer_statement.length >
                                    0 &&
                                  question?.answer?.answer_statement.find(
                                    (old_answer) => old_answer == option
                                  );
                                return (
                                  <div
                                    className={`question-answer`}
                                    key={option.id}
                                  >
                                    <FormControlLabel
                                      value={option}
                                      id={`capsule${index}`}
                                      className="answer"
                                      control={
                                        <Checkbox
                                          checked={
                                            checkbox_answer ? true : false
                                          }
                                          disabled
                                        />
                                      }
                                      disabled
                                      label={option}
                                    />
                                  </div>
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        ) : question.question_type == "scaling" ? (
                          <FormControl>
                            <ul className="list-unstyled pt-1 mb-0">
                              <li className="row">
                                <div className="col-12">
                                  <GeneralQuestionStarRating
                                    question={question}
                                    find_answer={question.answer}
                                  />
                                </div>
                              </li>
                            </ul>
                          </FormControl>
                        ) : (
                          <FormControl style={{ width: "100%" }}>
                            <TextField
                              id="outlined-multiline-static"
                              multiline
                              rows={4}
                              className="mb-3"
                              value={question.answer?.answer_statement}
                              variant="outlined"
                              disabled
                            />
                          </FormControl>
                        )}
                        {
                          <div className="col-12 d-flex pt-2  justify-content-between">
                            <div>
                              {type === "responded" ? (
                                question?.answer?.comments?.length > 0 && (
                                  <button
                                    className="small-contained-button mt-2"
                                    onClick={() =>
                                      handleOpenReplyDrawer(question)
                                    }
                                  >
                                    Replies ({question?.answer?.comments.length}
                                    )
                                  </button>
                                )
                              ) : (
                                <>
                                  <button
                                    className="small-contained-button mt-2"
                                    onClick={() =>
                                      handleOpenReplyDrawer(question)
                                    }
                                  >
                                    Replies (
                                    {question?.answer?.comments?.length || 0})
                                  </button>
                                  <Tooltip title="Show Replies to Client">
                                    <Switch
                                      checked={question?.answer.show_replies}
                                      onChange={(e) =>
                                        handleChange(e, question)
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </div>

                            {question.answer?.document_url && (
                              <a
                                href={s3baseUrl + question.answer.document_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="button-link"
                              >
                                <button className="small-contained-button mt-2">
                                  View Document
                                </button>
                              </a>
                            )}
                          </div>
                        }
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })
            ) : (
              <RecordNotFound title="Questions" />
            )}
          </div>
        </div>
      </div>
      {type !== "responded" && (
        <div className="row">
          <div className="col-12">
            <h2 ref={titleRef} className="mb-4">
              {replies?.length == 0
                ? ""
                : userInfo?.first_name && userInfo.first_name + "'s" + " Reply"}
            </h2>
          </div>
          <SlefImageReplies
            replies={replies}
            created_for={"self_image"}
            get_questions_list={detailQuestionHistory}
          />

          {/* {replies?.map((reply) => {
          return (
            <>
              <div className="col-12 mb-3 d-flex">
                <CircleIcon />
                <div
                  className="ms-2"
                  dangerouslySetInnerHTML={{
                    __html: reply?.message,
                  }}
                ></div>
              </div>
              <div className="col-12 mb-1">
                {reply?.audio_file && (
                  <audio
                    className="w-100"
                    src={s3baseUrl + reply?.audio_file}
                    controls
                  />
                )}
              </div>
              <div className="col-6 mb-5 text-muted">
                {moment(reply.createdAt).format("DD-MM-YYYY")}
              </div>
              {reply.action_by == "consultant_user" && (
                <div className="col-6 text-end">
                  <button
                    className="small-contained-button"
                    onClick={() => handleAgreeDelete(reply)}
                  >
                    Delete
                  </button>
                </div>
              )}
            </>
          );
        })} */}
          <AddKimsReply goalDetail={detailQuestionHistory} />
        </div>
      )}
      {/* <div className="row" id="dynamite-reply">
        <AddKimsReply goalDetail={detailQuestionHistory} />
      </div> */}
      <CustomDrawer
        isOpenDrawer={openAnswerHistory}
        onOpenDrawer={handleOpenAnswerDrawer}
        onCloseDrawer={handleCloseAnswerDrawer}
        pageTitle={`Answer History`}
        componentToPassDown={
          <AnswerHistory
            data={answerHistoryData}
            member_id={params.id}
            detailQuestionHistory={detailQuestionHistory}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={openReply}
        onOpenDrawer={handleOpenReplyDrawer}
        onCloseDrawer={handleCloseReplyDrawer}
        pageTitle={`Replies`}
        name={state?.name}
        paperProps={{ sx: { width: 580, border: "none", overflow: "hidden" } }}
        componentToPassDown={
          <SelfImageQuestionsReply
            data={replyData}
            detailQuestionHistory={detailQuestionHistory}
            onCloseDrawer={handleCloseReplyDrawer}
            reload={detailQuestionHistory}
            type={type}
          />
        }
      />
    </div>
  );
};

export default SelfImageDetail;
