import React, { useEffect, useRef, useState } from "react";
import { s3baseUrl } from "src/config/config";
import LoopIcon from "@mui/icons-material/Loop";
import RepeatOneSharpIcon from "@mui/icons-material/RepeatOneSharp";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { update_Played_times } from "src/DAL/Tracking/Tracking";
import { useParams } from "react-router-dom";

export default function ContentAudioPlayer({ item, calenderDate }) {
  const [isLooping, setIsLooping] = useState(false);
  const [listenCount, setListenCount] = useState();
  const audioRef = useRef(null);
  const params = useParams();
  const handleCompletedContent = async () => {
    let postData = {
      content: item.content,
      id: item.id,
      date: calenderDate
        ? moment(calenderDate).format("DD-MM-YYYY")
        : moment(new Date()).format("DD-MM-YYYY"),
    };
    const result = await update_Played_times(postData);
    if (result.code === 200) {
      setListenCount((prevCount) => prevCount + 1);
    }
  };

  const handleAudioEnd = () => {
    handleCompletedContent();
  };

  const handleToggleLooping = () => {
    setIsLooping(!isLooping);
  };

  useEffect(() => {
    if (params.id) {
      return;
    }
    const audioElement = audioRef.current;
    if (audioElement) {
      // Handle looping manually for "loop" functionality
      const handleTimeUpdate = () => {
        if (
          audioElement.currentTime > 0 &&
          Math.abs(audioElement.currentTime - audioElement.duration) < 0.3
        ) {
          audioElement.currentTime = 0; // Reset to start
          audioElement.play(); // Replay
          handleCompletedContent(); // Call completion logic
        }
      };

      if (isLooping) {
        audioElement.addEventListener("timeupdate", handleTimeUpdate);
      }

      return () => {
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [isLooping]);

  useEffect(() => {
    setListenCount(item.listen_count);
  }, [item]);

  return (
    <div className="audio-container">
      <span className="hide-audio cursor-pointer" onClick={handleToggleLooping}>
        <Tooltip title={isLooping ? "Play In Loop" : "Play Once"}>
          {isLooping ? <LoopIcon /> : <RepeatOneSharpIcon />}
        </Tooltip>
      </span>
      <div>
        <audio
          className="w-100"
          src={s3baseUrl + item.content}
          controls
          ref={audioRef}
          onEnded={!isLooping && !params.id ? handleAudioEnd : undefined} // Normal onEnded behavior when not looping
        />
        {listenCount > 0 && (
          <div className="audio-progress-bar mt-2">
            <span style={{ fontSize: "12px", color: "#f6bd4b" }}>
              Play Count :{" "}
              {listenCount && (
                <span style={{ color: "#f6bd4b" }}>{listenCount} Times</span>
              )}{" "}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
